<template>
  <div>
    <v-row no-glutters>
      <v-col cols="12">
        <v-card class="pa-4 px-sm-8 py-sm-6 elevation-0 rounded-lg mb-6" color="secbg">
          <p class="fs-24 font-weight-bold mb-1">Featured Collections</p>
          <p class="subtitle-2 subtext--text mb-6">Collections of stocks (baskets) curated by experts</p>
          <v-card v-if="bestcollsdata.length > 0" class="crd-trn d-inline-flex overflow-x-auto elevation-0 no-scroll" width="100%">
            <v-card v-for="(b, l) in bestcollsdata" :key="l" class="px-5 pb-3 pt-4 mr-4 rounded-lg elevation-0" min-width="184px" style="border: thin solid var(--outline) !important">
              <div class="mb-1">
                <img :src="require(`@/assets/colls/bcolls_${b.image}.svg`)" />
              </div>
              <v-list-item-title class="subtitle-1 font-weight-bold mb-1">{{ b.title }}</v-list-item-title>
              <p class="fs-12 subtext--text mb-4">{{ b.subtitle }}</p>
              <p class="subtitle-1 font-weight-bold mb-0">{{ b.baskets }} baskets</p>
            </v-card>
          </v-card>
          <v-card v-else class="d-inline-flex overflow-x-auto elevation-0 no-scroll mb-0 crd-trn" width="100%">
            <v-skeleton-loader v-for="n in 10" :key="n" class="pb-3 mr-4 rounded-lg elevation-0" min-width="184" height="200" type="card"></v-skeleton-loader>
          </v-card>
        </v-card>

        <v-toolbar class="tool-sty elevation-0" height="40px" color="cardbg" dense>
          <p class="title font-weight-bold mb-0">Top Collections</p>
          <v-spacer></v-spacer>
          <!-- <v-text-field v-model="searchkeyfield" class="d-none d-lg-block d-xl-block body-2" prepend-inner-icon="mdi-magnify" hide-details style="max-width: 270px" label="Search" single-line background-color="secbg" filled dense rounded /> -->
          <v-btn icon class="ms-1 d-none d-lg-block d-xl-block" color="#000" @click="listView = !listView">
            <v-icon dense>{{ listView ? "mdi-view-day" : "mdi-view-dashboard" }}</v-icon>
          </v-btn>
        </v-toolbar>

        <div class="d-none d-lg-block">
          <v-row class="mt-2" v-if="!loading && listView">
            <v-col class="mb-2" v-for="(item, i) in collsdatas" :key="i" cols="12" sm="6" md="4" xl="3">
              <v-card class="rounded-lg px-4 pos-rlt cursor-p" outlined @click="setSinglecoll(item)">
                <p class="mb-0 text-right lh-16">
                  <v-chip class="px-2 text-capitalize" style="top: 8px; z-index: 1px" x-small :text-color="item.access === 'free' ? 'maingreen' : 'primary'" :color="item.access === 'free' ? 'secgreen' : 'primary lighten-5'"
                    ><span>{{ item.access }}</span></v-chip
                  >
                </p>
                <v-list-item class="px-0">
                  <v-list-item-avatar size="48" class="mr-2 mt-auto">
                    <img v-if="item.basket_img" width="100%" :src="modifyurl(item.basket_img)" />
                    <v-avatar v-else :color="i >= 9 ? letters[i % 10] : letters[i]" size="48">
                      <span class="title white--text font-weight-bold" v-if="item.basket_title">{{ item.basket_title.split(" ")[0][0] }}{{ item.basket_title.split(" ")[1] ? item.basket_title.split(" ")[1][0] : "" }}</span>
                    </v-avatar>
                  </v-list-item-avatar>
                  <v-list-item-content class="pt-0">
                    <v-list-item-title class="text-uppercase font-weight-medium fs-13 mb-1">
                      {{ item.basket_title }}
                    </v-list-item-title>

                    <v-list-item-subtitle class="fs-12">
                      by
                      <span class="font-weight-medium">{{ item.master_name }}</span>
                    </v-list-item-subtitle>
                  </v-list-item-content>

                  <div v-if="item.cagr && item.cagr['cagr_3year']" style="height: 40px">
                    <p class="mt-6 text-caption font-weight-bold mb-0 pb-0 fs-10">3Y CAGR</p>
                    <span
                      class="fs-12 mt-0 pt-0"
                      :class="!uid ? 'blur-text' : ''"
                      :style="{
                        color: !uid ? 'transparent' : parseInt(item.cagr?.cagr_3year) > 0 ? 'var(--maingreen)' : 'var(--mainred)',
                        textShadow: !uid ? '#4BAC3C 0 0 5px' : '',
                      }"
                      >{{ item.cagr ? (item.cagr.cagr_3year ? item.cagr.cagr_3year : "") : "" }}%</span
                    >
                  </div>
                </v-list-item>
                <v-list-item-subtitle class="mb-2">
                  <v-chip color="secbg" text-color="maintext" label class="text--secondary mr-1 text-capitalize px-1" x-small v-for="(j, l) in item.tags" :key="l">{{ j }}</v-chip></v-list-item-subtitle
                >
                <div style="height: 40px">
                  <v-list-item three-line class="px-0">
                    <v-list-item-content style="height: 40px" class="py-0">
                      <v-list-item-subtitle class="mb-auto"> {{ item.mang_sht_con }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </div>
                <v-list-item class="px-0">
                  <v-list-item-content>
                    <div class="fs-13">
                      <span class="subtext--text">Min. Invest</span>
                      <p class="font-weight-bold body-2 mb-0">
                        {{ item.price ? Number(item.price).toFixed(2) : "0.00" }}
                      </p>
                    </div>
                  </v-list-item-content>
                  <v-list-item-content>
                    <v-btn @click.stop @click="uid ? setSinglecoll(item, true) : $emit('gologin')" block class="elevation-0 rounded-pill text-none primary--text font-weight-bold" color="#F1F3F8">Invest</v-btn>
                  </v-list-item-content>
                </v-list-item>
              </v-card>
            </v-col>
          </v-row>
          <v-row class="mt-2" v-else-if="!loading && !listView" no-gutters>
            <v-card v-for="(item, i) in collsdatas" :key="i" width="100%" class="my-3 py-2 px-4" outlined @click="setSinglecoll(item)">
              <v-row>
                <v-col cols="8">
                  <v-list-item class="px-0">
                    <v-list-item-avatar size="48" class="mr-2">
                      <img v-if="item.basket_img" width="100%" :src="modifyurl(item.basket_img)" />
                      <v-avatar v-else :color="i >= 9 ? letters[i % 10] : letters[i]" size="48">
                        <span class="title white--text font-weight-bold" v-if="item.basket_title">{{ item.basket_title.split(" ")[0][0] }}{{ item.basket_title.split(" ")[1] ? item.basket_title.split(" ")[1][0] : "" }}</span>
                      </v-avatar>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title class="text-uppercase subtitle-2 mb-1">
                        {{ item.basket_title }}
                      </v-list-item-title>

                      <v-list-item-subtitle class="fs-12">
                        by
                        <span class="font-weight-medium">{{ item.master_name }}</span>
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item-subtitle>
                    <v-chip color="secbg" text-color="maintext" label class="text--secondary mr-1 text-capitalize px-1" x-small v-for="(j, l) in item.tags" :key="l">{{ j }}</v-chip></v-list-item-subtitle
                  >
                  <p style="display: -webkit-box; -webkit-line-clamp: 2; -webkit-box-orient: vertical; overflow: hidden; text-overflow: ellipsis" class="mb-0 subtitle-2 txt-666 font-weight-regular mt-2 lh-20">
                    {{ item.mang_sht_con }}
                  </p>
                </v-col>
                <v-col cols="3" class="d-flex align-center justify-center">
                  <div class="fs-13 mr-6" v-if="item.cagr && item.cagr['cagr_3year']">
                    <span class="subtext--text">3Y CAGR</span><br />

                    <span
                      class="fs-12 mt-0 pt-0"
                      :class="{
                        'blur-text': !uid,
                      }"
                      :style="{
                        color: !uid ? 'transparent' : parseInt(item.cagr?.cagr_3year) > 0 ? 'var(--maingreen)' : 'var(--mainred)',
                        textShadow: !uid ? '#4BAC3C 0 0 5px' : '',
                      }"
                      >{{ item.cagr ? (item.cagr.cagr_3year ? item.cagr.cagr_3year : "") : "" }}%</span
                    >
                  </div>
                  <div class="fs-13">
                    <span class="subtext--text">Min. Invest</span>
                    <p class="font-weight-bold body-2 mb-0">
                      {{ item.price ? Number(item.price).toFixed(2) : "0.00" }}
                    </p>
                  </div>
                </v-col>
                <v-col cols="1" class="d-flex align-center justify-end pos-rlt">
                  <div class="py-0 pos-abs" style="top: 8px; right: 4px">
                    <v-chip class="px-2 text-capitalize" x-small :text-color="item.access === 'free' ? 'maingreen' : 'primary'" :color="item.access === 'free' ? 'secgreen' : 'primary lighten-5'"
                      ><span>{{ item.access }}</span></v-chip
                    >
                  </div>
                  <v-btn @click.stop @click="uid ? setSinglecoll(item, true) : $emit('gologin')" class="elevation-0 rounded-pill text-none primary--text font-weight-bold mt-1 px-8" color="#F1F3F8">Invest</v-btn>
                </v-col>
              </v-row>
            </v-card>
          </v-row>
          <v-row class="mt-2" v-if="loading">
            <v-col class="mb-2" cols="12" sm="6" md="4" v-for="i in 6" :key="i">
              <v-skeleton-loader type="image" class="custom-skeleton-loader"></v-skeleton-loader>
            </v-col>
          </v-row>
        </div>

        <div class="mb-12 d-block d-lg-none">
          <v-row class="mt-1">
            <v-col class="" v-for="(item, i) in collsdatas" :key="i" cols="12" sm="6" md="4" xl="3">
              <v-card class="rounded-lg px-4 pos-rlt cursor-p" outlined @click="setSinglecoll(item)">
                <p class="mb-0 text-right lh-16">
                  <v-chip class="px-2 text-capitalize" style="top: 8px; z-index: 1px" x-small :text-color="item.access === 'free' ? 'maingreen' : 'primary'" :color="item.access === 'free' ? 'secgreen' : 'primary lighten-5'"
                    ><span>{{ item.access }}</span></v-chip
                  >
                </p>
                <v-list-item class="px-0">
                  <v-list-item-avatar size="48" class="mr-2 mt-auto">
                    <img v-if="item.basket_img" width="100%" :src="modifyurl(item.basket_img)" />
                    <v-avatar v-else :color="i >= 9 ? letters[i % 10] : letters[i]" size="48">
                      <span class="title white--text font-weight-bold" v-if="item.basket_title">{{ item.basket_title.split(" ")[0][0] }}{{ item.basket_title.split(" ")[1] ? item.basket_title.split(" ")[1][0] : "" }}</span>
                    </v-avatar>
                  </v-list-item-avatar>
                  <v-list-item-content class="pt-0">
                    <v-list-item-title class="text-uppercase font-weight-medium fs-13 mb-1">
                      {{ item.basket_title }}
                    </v-list-item-title>

                    <v-list-item-subtitle class="fs-12">
                      by
                      <span class="font-weight-medium">{{ item.master_name }}</span>
                    </v-list-item-subtitle>
                  </v-list-item-content>

                  <div v-if="item.cagr && item.cagr['cagr_3year']" style="height: 40px">
                    <p class="mt-6 text-caption font-weight-bold mb-0 pb-0 fs-10">3Y CAGR</p>
                    <span
                      class="fs-12 mt-0 pt-0"
                      :class="{
                        'blur-text': !uid,
                      }"
                      :style="{
                        color: !uid ? 'transparent' : parseInt(item.cagr?.cagr_3year) > 0 ? 'var(--maingreen)' : 'var(--mainred)',
                        textShadow: !uid ? '#4BAC3C 0 0 5px' : '',
                      }"
                      >{{ item.cagr ? (item.cagr.cagr_3year ? item.cagr.cagr_3year : "") : "" }}%</span
                    >
                  </div>
                </v-list-item>
                <v-list-item-subtitle class="mb-2">
                  <v-chip color="secbg" text-color="maintext" label class="text--secondary mr-1 text-capitalize px-1" x-small v-for="(j, l) in item.tags" :key="l">{{ j }}</v-chip></v-list-item-subtitle
                >
                <div style="height: 40px">
                  <v-list-item three-line class="px-0">
                    <v-list-item-content style="height: 40px" class="py-0">
                      <v-list-item-subtitle class="mb-auto"> {{ item.mang_sht_con }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </div>
                <v-list-item class="px-0">
                  <v-list-item-content>
                    <div class="fs-13">
                      <span class="subtext--text">Min. Invest</span>
                      <p class="font-weight-bold body-2 mb-0">
                        {{ item.price ? Number(item.price).toFixed(2) : "0.00" }}
                      </p>
                    </div>
                  </v-list-item-content>
                  <v-list-item-content>
                    <v-btn @click.stop @click="uid ? setSinglecoll(item, true) : $emit('gologin')" block class="elevation-0 rounded-pill text-none primary--text font-weight-bold" color="#F1F3F8">Invest</v-btn>
                  </v-list-item-content>
                </v-list-item>
              </v-card>
            </v-col>
          </v-row>

          <v-row class="mt-2 d-block d-lg-none" v-if="loading">
            <v-col class="mb-2" cols="12" sm="6" md="4" v-for="i in 6" :key="i">
              <v-skeleton-loader type="image" class="custom-skeleton-loader"></v-skeleton-loader>
            </v-col>
          </v-row>
        </div>
      </v-col>
      <v-col cols="3" class="d-none"></v-col>
    </v-row>
  </div>
</template>

<script>
import eventBus from "../../../eventBus.js";

export default {
  data: () => ({
    collstype: 0,
    collsdatas: [],
    loading: true,
    listView: true,

    letters: ["#F44336", "#E91E63", "#9C27B0", "#673AB7", "#3F51B5", "#2196F3", "#03A9F4", "#00BCD4", "#009688", "#FFC107"],

    opensearch: null,
    showtable: 24,
    uid: null,

    bestcollsdata: [
      {
        image: "highrisk",
        subtitle: "Ideal for investors with a high risk appetite",
        title: "High Risk",
        baskets: 12,
      },
      {
        image: "balwel",
        subtitle: "Stable income and growth well balanced",
        title: "Balanced Wealth",
        baskets: 10,
      },
      {
        image: "infinity",
        subtitle: "Hybrid of active and passive",
        title: "Top 100 Infinity",
        baskets: 8,
      },
      {
        image: "gems",
        subtitle: "Diversify your portfolio globally",
        title: "Hidden Gems",
        baskets: 16,
      },
      {
        image: "star",
        subtitle: "Long term wealth creation at low cost",
        title: "Popular Star",
        baskets: 2,
      },
    ],
  }),

  computed: {},
  created() {
    this.token = "";
    this.uid = "";
  },

  async mounted() {
    eventBus.$emit("tabBar-load");
    eventBus.$emit("login-event");
    this.$emit("receive", "collection");
    eventBus.$on("setRec-event", (value) => {
      if (value == "stat_ok") {
        this.$emit("receive", "collection");
      } else if (value && Object.entries(value).length > 0) {
        this.getCollsdata(value);
      }
    });
    eventBus.$on("user-event", () => {
      let res = sessionStorage.getItem("c3RhdHVz");
      if (res == "dmFsaWR1c2Vy") {
        this.token = sessionStorage.getItem("usession");
        this.uid = sessionStorage.getItem("userid");
      }
    });
  },
  beforeDestroy() {
    eventBus.$off("setRec-event");
  },
  methods: {
    getCollsdata(receive) {
      this.collsdatas = receive.msg && receive.msg.length > 0 ? receive.msg : [];
      this.loading = false;
    },
    setSinglecoll(item, value) {
      this.$router.push({
        name: "collection single",
        params: {best: item.id, name: item.basket_title, trigger: value},
      });
    },
  },
};
</script>

<style scoped>
.custom-skeleton-loader >>> .v-skeleton-loader__image {
  height: 198px !important;
}
</style>
