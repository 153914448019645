// var requestOptions = {
//   method: 'POST',
//   redirect: 'follow'
// };
// const response = await axios('https://sess.mynt.in/getoken', requestOptions);

// export const uid = response.data.uid;
// export const tok = response.data.token;
export const mynturl = { }
// export const mynturl = { myntapi: "https://go.mynt.in/NorenWClientTP/", webSocketURL: "wss://go.mynt.in/NorenWSWeb/", source: "API" }

export const myntDCUrl = "https://go.mynt.in/chartApi/";

// export const source = "TV"
// export const source = "API"
// export const source = "WEB"
export const excs = "NSE"

export default {
  // NorenWClientTV 
  // NorenWClientTP
  // NorenWClientWeb
  banklogo: 'https://ekycbe.mynt.in/zebu',

  imgicon: "https://besim.zebull.in/static/equity/icons/",

  eqapi: 'https://v3.mynt.in/equity/',
  eqapiD: 'http://192.168.5.138:5000/',

  copy: 'https://copy.mynt.in/',

  // mfapi: 'http://192.168.5.91:5000/',
  mfapi: 'https://v3.mynt.in/mf/',
  exmynt: 'https://be.zebull.in/',
  // Dmfapi: 'http://192.168.5.192:5000/',

  bondapi: 'https://besim.zebull.in/',
  iposapi: 'https://v3.mynt.in/ipo/',

  // collapi: 'http://192.168.5.179:5111/',
  collapi: 'https://v3.mynt.in/collection/',


  ledger: 'https://rekycbe.mynt.in/',
  upiurl: 'https://fundapi.mynt.in/',
  repapi: 'https://rekycbe.mynt.in/report/',

  asvrapi: 'https://asvr.mynt.in/bcast/',

  autho: 'https://rekycbe.mynt.in/autho/',
  sessapi: "https://sess.mynt.in/",

  zebuApiUrl: "https://be.mynt.in/",
}
