<template>
  <div>
    <div v-if="optionchainid" class="tvcharts">
      <v-card color="cardbg" width="100%" class="rounded-md" style="overflow: hidden">
        <div>
          <v-toolbar flat dense class="tool-sty crd-trn pr-4">
            <p class="font-weight-bold mt-1 mb-0 mr-8 lh-24">{{ optionStockName }}</p>

            <v-menu offset-y>
              <template v-slot:activator="{on, attrs}">
                <div v-bind="attrs" v-on="on">
                  <v-btn color="secbg" class="elevation-0 rounded-lg text-none fs-14 mr-4 px-2 text-capitalize">
                    {{ lsexdval ? lsexdval : "" }}

                    <span class="ml-4 font-weight-bold fs-12">{{ lsexdval ? daydiff + "(D)" : "" }}</span>
                    <v-icon>mdi-chevron-down</v-icon>
                  </v-btn>
                </div>
              </template>
              <v-list dense style="max-height: 320px; overflow-y: scroll">
                <v-list-item-group @change="optionChainDate()" v-model="lsexdfilter" active-class="primary--text">
                  <v-list-item v-for="(item, index) in lsexd" :key="index">
                    <v-list-item-title>{{ item }}</v-list-item-title>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-menu>

            <v-menu offset-y>
              <template v-slot:activator="{on, attrs}">
                <div v-bind="attrs" v-on="on">
                  <v-btn color="secbg" class="elevation-0 rounded-lg text-none fs-14 px-2 text-capitalize">
                    {{ chainCount ? (chainCount == "50" ? "All" : chainCount) : "" }}
                    <v-icon>mdi-chevron-down</v-icon>
                  </v-btn>
                </div>
              </template>
              <v-list dense>
                <v-list-item-group @change="optionChainDate()" v-model="ccfilter" active-class="primary--text">
                  <v-list-item v-for="(item, index) in ['5', '10', '15', '30', 'All']" :key="index">
                    <v-list-item-title>{{ item }}</v-list-item-title>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-menu>
            <v-spacer></v-spacer>

            <v-btn tile icon @click="setBaskorder('create')">
              <img width="24px" src="@/assets/usermenu/9opt.svg" />
            </v-btn>
            <v-btn v-if="drawer == false" tile icon @click="drawer = true">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" width="28" height="28">
                <g fill="currentColor" fill-rule="evenodd">
                  <path fill-rule="nonzero" d="M14 17a3 3 0 1 1 0-6 3 3 0 0 1 0 6zm0-1a2 2 0 1 0 0-4 2 2 0 0 0 0 4z"></path>
                  <path
                    d="M5.005 16A1.003 1.003 0 0 1 4 14.992v-1.984A.998.998 0 0 1 5 12h1.252a7.87 7.87 0 0 1 .853-2.06l-.919-.925c-.356-.397-.348-1 .03-1.379l1.42-1.42a1 1 0 0 1 1.416.007l.889.882A7.96 7.96 0 0 1 12 6.253V5c0-.514.46-1 1-1h2c.557 0 1 .44 1 1v1.253a7.96 7.96 0 0 1 2.06.852l.888-.882a1 1 0 0 1 1.416-.006l1.42 1.42a.999.999 0 0 1 .029 1.377s-.4.406-.918.926a7.87 7.87 0 0 1 .853 2.06H23c.557 0 1 .447 1 1.008v1.984A.998.998 0 0 1 23 16h-1.252a7.87 7.87 0 0 1-.853 2.06l.882.888a1 1 0 0 1 .006 1.416l-1.42 1.42a1 1 0 0 1-1.415-.007l-.889-.882a7.96 7.96 0 0 1-2.059.852v1.248c0 .56-.45 1.005-1.008 1.005h-1.984A1.004 1.004 0 0 1 12 22.995v-1.248a7.96 7.96 0 0 1-2.06-.852l-.888.882a1 1 0 0 1-1.416.006l-1.42-1.42a1 1 0 0 1 .007-1.415l.882-.888A7.87 7.87 0 0 1 6.252 16H5.005zm3.378-6.193l-.227.34A6.884 6.884 0 0 0 7.14 12.6l-.082.4H5.005C5.002 13 5 13.664 5 14.992c0 .005.686.008 2.058.008l.082.4c.18.883.52 1.71 1.016 2.453l.227.34-1.45 1.46c-.004.003.466.477 1.41 1.422l1.464-1.458.34.227a6.959 6.959 0 0 0 2.454 1.016l.399.083v2.052c0 .003.664.005 1.992.005.005 0 .008-.686.008-2.057l.399-.083a6.959 6.959 0 0 0 2.454-1.016l.34-.227 1.46 1.45c.003.004.477-.466 1.422-1.41l-1.458-1.464.227-.34A6.884 6.884 0 0 0 20.86 15.4l.082-.4h2.053c.003 0 .005-.664.005-1.992 0-.005-.686-.008-2.058-.008l-.082-.4a6.884 6.884 0 0 0-1.016-2.453l-.227-.34 1.376-1.384.081-.082-1.416-1.416-1.465 1.458-.34-.227a6.959 6.959 0 0 0-2.454-1.016L15 7.057V5c0-.003-.664-.003-1.992 0-.005 0-.008.686-.008 2.057l-.399.083a6.959 6.959 0 0 0-2.454 1.016l-.34.227-1.46-1.45c-.003-.004-.477.466-1.421 1.408l1.457 1.466z"
                  ></path>
                </g>
              </svg>
            </v-btn>
          </v-toolbar>
          <v-divider></v-divider>
          <div>
            <v-card v-if="coractdata" class="crd-trn elevation-0 rounded-0" style="overflow: hidden">
              <v-simple-table class="no-scroll crd-trn" fixed-header height="calc(100vh - 138px)" dense :style="`--my-simtblwidth-var:${simtblwidth};--my-simtblscroll-var:${simtblscroll};`">
                <template v-slot:default>
                  <thead class="secbg">
                    <tr>
                      <th width="60px" v-if="thetacheckbox" colspan="1">
                        <v-card class="crd-trn elevation-0 text-center subtext--text" tile>
                          <span class="optionheadsty">THETA</span>
                        </v-card>
                      </th>
                      <th width="60px" v-if="vagacheckbox" colspan="1">
                        <v-card class="crd-trn elevation-0 text-center subtext--text" tile>
                          <span class="optionheadsty">VEGA</span>
                        </v-card>
                      </th>
                      <th width="60px" v-if="gamacheckbox" colspan="1">
                        <v-card class="crd-trn elevation-0 text-center subtext--text" tile>
                          <span class="optionheadsty">GAMA</span>
                        </v-card>
                      </th>
                      <th width="60px" v-if="deltacheckbox" colspan="1">
                        <v-card class="crd-trn elevation-0 text-center subtext--text" tile>
                          <span class="optionheadsty">DELTA</span>
                        </v-card>
                      </th>
                      <th colspan="2">
                        <v-card class="crd-trn elevation-0 text-center subtext--text" tile>
                          <span class="optionheadsty">VOL<span class="optionchsty">(lac)</span></span>
                        </v-card>
                      </th>
                      <th width="96px" colspan="2">
                        <v-card class="crd-trn elevation-0 text-center subtext--text" tile>
                          <span class="optionheadsty">OI<span class="optionchsty">(ch)</span></span>
                        </v-card>
                      </th>
                      <th v-if="bitcheckbox" colspan="1">
                        <v-card class="crd-trn elevation-0 text-center subtext--text" tile>
                          <span class="optionheadsty">BID</span>
                        </v-card>
                      </th>
                      <th v-if="askcheckbox" colspan="1">
                        <v-card class="crd-trn elevation-0 text-center subtext--text" tile>
                          <span class="optionheadsty">ASK</span>
                        </v-card>
                      </th>
                      <th width="100px" colspan="2">
                        <v-card class="crd-trn elevation-0 text-center subtext--text" tile>
                          <span class="optionheadsty">CH</span>
                        </v-card>
                      </th>
                      <th colspan="2">
                        <v-card class="crd-trn elevation-0 text-center subtext--text" tile>
                          <span class="optionheadsty">LTP</span>
                        </v-card>
                      </th>
                      <th width="60px" v-if="ivcheckbox" colspan="1">
                        <v-card class="crd-trn elevation-0 text-center subtext--text" tile>
                          <span class="optionheadsty">IV</span>
                        </v-card>
                      </th>
                      <th colspan="4" style="border-left: thin solid rgba(0, 0, 0, 0.12); border-right: thin solid rgba(0, 0, 0, 0.12)">
                        <v-card class="crd-trn elevation-0 text-center subtext--text" tile>
                          <span class="mx-4 optionheadsty">STRIKES</span>
                        </v-card>
                      </th>
                      <th width="60px" v-if="ivcheckbox" colspan="1">
                        <v-card class="crd-trn elevation-0 text-center subtext--text" tile>
                          <span class="optionheadsty">IV</span>
                        </v-card>
                      </th>
                      <th colspan="2">
                        <v-card class="crd-trn elevation-0 text-center subtext--text" tile>
                          <span class="optionheadsty">LTP</span>
                        </v-card>
                      </th>
                      <th width="100px" colspan="2">
                        <v-card class="crd-trn elevation-0 text-center subtext--text" tile>
                          <span class="optionheadsty">CH</span>
                        </v-card>
                      </th>
                      <th v-if="bitcheckbox" colspan="1">
                        <v-card class="crd-trn elevation-0 text-center subtext--text" tile>
                          <span class="optionheadsty">BID</span>
                        </v-card>
                      </th>
                      <th v-if="askcheckbox" colspan="1">
                        <v-card class="crd-trn elevation-0 text-center subtext--text" tile>
                          <span class="optionheadsty">ASK</span>
                        </v-card>
                      </th>
                      <th width="96px" colspan="2">
                        <v-card class="crd-trn elevation-0 text-center subtext--text" tile>
                          <span class="optionheadsty">OI<span class="optionchsty">(ch)</span></span>
                        </v-card>
                      </th>
                      <th colspan="2">
                        <v-card class="crd-trn elevation-0 text-center subtext--text" tile>
                          <span class="optionheadsty">VOL<span class="optionchsty">(lac)</span></span>
                        </v-card>
                      </th>
                      <th width="60px" v-if="deltacheckbox" colspan="1">
                        <v-card class="crd-trn elevation-0 text-center subtext--text" tile>
                          <span class="optionheadsty">DELTA</span>
                        </v-card>
                      </th>
                      <th width="60px" v-if="gamacheckbox" colspan="1">
                        <v-card class="crd-trn elevation-0 text-center subtext--text" tile>
                          <span class="optionheadsty">GAMA</span>
                        </v-card>
                      </th>
                      <th width="60px" v-if="vagacheckbox" colspan="1">
                        <v-card class="crd-trn elevation-0 text-center subtext--text" tile>
                          <span class="optionheadsty">VEGA</span>
                        </v-card>
                      </th>
                      <th width="60px" v-if="thetacheckbox" colspan="1">
                        <v-card class="crd-trn elevation-0 text-center subtext--text" tile>
                          <span class="optionheadsty">THETA</span>
                        </v-card>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr class="totrhover" v-for="(script, k) in upcallSO" :key="'calloption' + k">
                      <th v-if="thetacheckbox" colspan="1" class="text-center pa-0" :style="opdatabgs">
                        <div width="100%" height="32px" class="optiondatasty" v-html="upcallSO[k].theta ? upcallSO[k].theta : '0.0000'"></div>
                      </th>
                      <th v-if="vagacheckbox" colspan="1" class="text-center pa-0" :style="opdatabgs">
                        <div width="100%" height="32px" class="optiondatasty" v-html="upcallSO[k].vega ? upcallSO[k].vega : '0.0000'"></div>
                      </th>
                      <th v-if="gamacheckbox" colspan="1" class="text-center pa-0" :style="opdatabgs">
                        <div width="100%" height="32px" class="optiondatasty" v-html="upcallSO[k].gamma ? upcallSO[k].gamma : '0.0000'"></div>
                      </th>
                      <th v-if="deltacheckbox" colspan="1" class="text-center pa-0" :style="opdatabgs">
                        <div width="100%" height="32px" class="optiondatasty" v-html="upcallSO[k].delta ? upcallSO[k].delta : '0.0000'"></div>
                      </th>
                      <th colspan="2" class="text-center opdatas" :style="opdatabgs">
                        <span class="optiondatasty" :id="`upcall${upcallSO[k].token}vol`" v-html="upcallSO[k].vol ? upcallSO[k].vol : '0.00'"></span>
                      </th>
                      <th colspan="2" class="text-center opdatas opdatacalluphov" :style="opdatabgs">
                        <div class="callprogress" :style="`--my-width-var:${upcallSO[k].pro}%;`" :id="`upcall${upcallSO[k].token}pro`">
                          <!-- <div class="callprogress" :style="`--my-width-var:${(((upcallSO[k].oi - barCallsOi) / barCallsOi) * 100 + 100)?.toFixed(0)}%;`"> -->
                          <span class="optiondatasty optionbartext">
                            <span :id="`upcall${upcallSO[k].token}oi`">{{ upcallSO[k].oi ? upcallSO[k].oi : "0.00" }}</span>
                            <span :id="`upcall${upcallSO[k].token}oiclr`" :style="`color:${upcallSO[k].oich > 0 ? 'var(--maingreen)' : upcallSO[k].oich < 0 ? 'var(--mainred)' : 'none'};`" class="optionchsty">
                              <span :id="`upcall${upcallSO[k].token}oich`">({{ upcallSO[k].oich ? upcallSO[k].oich : "0.00" }})</span>
                            </span>
                          </span>
                        </div>
                      </th>
                      <th v-if="bitcheckbox" colspan="1" class="text-center pa-0" :style="opdatabgs">
                        <div width="100%" height="32px" class="optiondatasty" :id="`upcall${upcallSO[k].token}bid`" v-html="upcallSO[k].bid ? upcallSO[k].bid : '0.00'"></div>
                      </th>
                      <th v-if="askcheckbox" colspan="1" class="text-center pa-0" :style="opdatabgs">
                        <div width="100%" height="32px" class="optiondatasty" :id="`upcall${upcallSO[k].token}ask`" v-html="upcallSO[k].ask ? upcallSO[k].ask : '0.00'"></div>
                      </th>
                      <th colspan="2" class="text-center opdatas" :style="opdatabgs">
                        <span :style="`color:${upcallSO[k].ch > 0 ? 'var(--maingreen)' : upcallSO[k].ch < 0 ? 'var(--mainred)' : ''};`" :id="`upcall${upcallSO[k].token}chpclr`" class="optiondatasty"
                          ><span :id="`upcall${upcallSO[k].token}ch`">{{ upcallSO[k].ch ? upcallSO[k].ch : "0.00" }}</span
                          ><span :id="`upcall${upcallSO[k].token}chp`" class="optionchsty"> ({{ upcallSO[k].chp ? upcallSO[k].chp : "0.00" }}%)</span>
                        </span>
                      </th>
                      <th colspan="2" class="text-center opdatacalluphov opdatas" :style="opdatabgs">
                        <span class="optiondatasty" :id="`upcall${upcallSO[k].token}ltp`" v-html="upcallSO[k].ltp ? upcallSO[k].ltp : '0.00'"> </span>
                        <tr class="opdatacallupbtn lfttrbtn" style="margin-left: -192px">
                          <th colspan="1" @click="chartOption(script)">
                            <div style="min-width: 32px; border-radius: 4px; border: thin solid var(--outline); padding: 1px 4px; background-color: var(--mainbg)" class="cursor-p mr-1">
                              <v-icon size="16" color="maintext">mdi-chart-line-variant</v-icon>
                            </div>
                          </th>
                          <th colspan="1" @click="basketOption(script)">
                            <div style="height: 22px; min-width: 32px; border-radius: 4px; border: thin solid var(--outline); padding: 1px 4px; background-color: var(--mainbg)" class="cursor-p mr-1">
                              <img width="16px" :src="require(`@/assets/usermenu/${$vuetify.theme.dark ? '9d' : '9'}.svg`)" />
                            </div>
                          </th>
                          <th colspan="1" @click="addOption(script)">
                            <div style="min-width: 32px; border-radius: 4px; border: thin solid var(--outline); padding: 1px 4px; background-color: var(--mainbg)" class="cursor-p mr-1">
                              <v-icon size="16" color="maintext">mdi-bookmark-outline</v-icon>
                            </div>
                          </th>
                          <th colspan="1" @click="sellOption(script)">
                            <div style="background-color: var(--mainred)" class="newhoverbtn newhoverbtnsize white--text cursor-p">S</div>
                          </th>
                          <th colspan="1" @click="buyOption(script)">
                            <div style="background-color: var(--maingreen)" class="newhoverbtn newhoverbtnsize white--text cursor-p">B</div>
                          </th>
                        </tr>
                      </th>
                      <th v-if="ivcheckbox" colspan="1" class="text-center pa-0" :style="opdatabgs">
                        <div width="100%" height="32px" class="optiondatasty" v-html="upcallSO[k].IV ? upcallSO[k].IV : '0.00'"></div>
                      </th>
                      <th colspan="4" class="text-center opstrprcborder opdatacalluphov pos-rlt">
                        <div class="lftbarstatus" :style="`--my-lftbrdclr-var:${upcallSO[k].bar};`" :id="`upcall${upcallSO[k].token}bar`"></div>
                        <v-tooltip left color="black" v-if="upcallSO[k].p">
                          <template v-slot:activator="{on, attrs}">
                            <v-icon v-bind="attrs" v-on="on" class="badghlpos pos-abs" :color="upcallSO[k].pz" @click="$router.push('/positions')" size="14">{{ upcallSO[k].icon }}</v-icon>
                          </template>
                          <span
                            ><b :class="upcallSO[k].qtyclr">{{ upcallSO[k].qtyp }}</b> Qty @ <span :class="upcallSO[k].px" class="font-weight-bold">{{ upcallSO[k].pmtm }}</span> MTM</span
                          >
                        </v-tooltip>
                        <span class="subtitle-2 font-weight-bold">{{ Number(upcallSO[k].strprc) ? upcallSO[k].strprc : "0.00" }}</span>
                        <v-tooltip right color="black" v-if="upputSO[k].p">
                          <template v-slot:activator="{on, attrs}">
                            <v-icon v-bind="attrs" v-on="on" class="badghrpos pos-abs" :color="upputSO[k].pz" @click="$router.push('/positions')" size="14">{{ upputSO[k].icon }}</v-icon>
                          </template>
                          <span
                            ><b :class="upputSO[k].qtyclr">{{ upputSO[k].qtyp }}</b> Qty @ <span :class="upputSO[k].px" class="font-weight-bold">{{ upputSO[k].pmtm }}</span> MTM</span
                          >
                        </v-tooltip>
                        <div class="rgtbarstatus" :style="`--my-rgtbrdclr-var:${upputSO[k].bar};`" :id="`upput${upputSO[k].token}bar`"></div>
                      </th>
                      <th v-if="ivcheckbox" colspan="1" class="text-center pa-0">
                        <div width="100%" height="32px" class="optiondatasty" v-html="upputSO[k].IV ? upputSO[k].IV : '0.00'"></div>
                      </th>
                      <th colspan="2" class="text-center opdatacalluphov opdatas">
                        <span class="optiondatasty" :id="`upput${upputSO[k].token}ltp`" v-html="upputSO[k].ltp ? upputSO[k].ltp : '0.00'"> </span>
                        <tr class="opdatacallupbtn uprghtrbtn">
                          <th colspan="1" @click="buyOption(script, upputSO)">
                            <div style="background-color: var(--maingreen)" class="newhoverbtn newhoverbtnsize white--text cursor-p">B</div>
                          </th>
                          <th colspan="1" @click="sellOption(script, upputSO)">
                            <div style="background-color: var(--mainred)" class="newhoverbtn newhoverbtnsize white--text cursor-p">S</div>
                          </th>
                          <th colspan="1" @click="addOption(script, upputSO)">
                            <div style="min-width: 32px; border-radius: 4px; border: thin solid var(--outline); padding: 1px 4px; background-color: var(--mainbg)" class="cursor-p">
                              <v-icon size="16" color="maintext">mdi-bookmark-outline</v-icon>
                            </div>
                          </th>
                          <th colspan="1" @click="basketOption(script, upputSO)">
                            <div style="height: 22px; min-width: 32px; border-radius: 4px; border: thin solid var(--outline); padding: 1px 4px; background-color: var(--mainbg)" class="cursor-p ml-1">
                              <img width="16px" :src="require(`@/assets/usermenu/${$vuetify.theme.dark ? '9d' : '9'}.svg`)" />
                            </div>
                          </th>
                          <th colspan="1" @click="chartOption(script, upputSO)">
                            <div style="min-width: 32px; border-radius: 4px; border: thin solid var(--outline); padding: 1px 4px; background-color: var(--mainbg)" class="cursor-p ml-1">
                              <v-icon size="16" color="maintext">mdi-chart-line-variant</v-icon>
                            </div>
                          </th>
                        </tr>
                      </th>
                      <th colspan="2" class="text-center opdatas">
                        <span :style="`color:${upputSO[k].ch > 0 ? 'var(--maingreen)' : upputSO[k].ch < 0 ? 'var(--mainred)' : ''};`" :id="`upput${upputSO[k].token}chpclr`" class="optiondatasty"
                          ><span :id="`upput${upputSO[k].token}ch`">{{ upputSO[k].ch ? upputSO[k].ch : "0.00" }}</span
                          ><span :id="`upput${upputSO[k].token}chp`" class="optionchsty"> ({{ upputSO[k].chp ? upputSO[k].chp : "0.00" }}%)</span>
                        </span>
                      </th>
                      <th v-if="bitcheckbox" colspan="1" class="text-center opdatas">
                        <span class="optiondatasty" :id="`upput${upputSO[k].token}bid`" v-html="upputSO[k].bid ? upputSO[k].bid : '0.00'"></span>
                      </th>
                      <th v-if="askcheckbox" colspan="1" class="text-center opdatas">
                        <span class="optiondatasty" :id="`upput${upputSO[k].token}ask`" v-html="upputSO[k].ask ? upputSO[k].ask : '0.00'"></span>
                      </th>
                      <th colspan="2" class="text-center opdatas opdatacalluphov">
                        <div class="putprogress" :style="`--my-width-var:${upputSO[k].pro}%;`" :id="`upput${upputSO[k].token}pro`">
                          <!-- <div class="putprogress" :style="`--my-width-var:${(((upputSO[k].oi - barPutsOi) / barPutsOi) * 100 + 100)?.toFixed(0)}%;`"> -->
                          <span class="optiondatasty optionbartext">
                            <span :id="`upput${upputSO[k].token}oi`">{{ upputSO[k].oi ? upputSO[k].oi : "0.00" }}</span>
                            <span :id="`upput${upputSO[k].token}oiclr`" :style="`color:${upputSO[k].oich > 0 ? 'var(--maingreen)' : upputSO[k].oich < 0 ? 'var(--mainred)' : 'none'};`" class="optionchsty">
                              <span :id="`upput${upputSO[k].token}oich`">({{ upputSO[k].oich ? upputSO[k].oich : "0.00" }})</span>
                            </span>
                          </span>
                        </div>
                      </th>
                      <th colspan="2" class="text-center opdatas">
                        <span class="optiondatasty" :id="`upput${upputSO[k].token}vol`" v-html="upputSO[k].vol ? upputSO[k].vol : '0.00'"></span>
                      </th>

                      <th v-if="deltacheckbox" colspan="1" class="text-center pa-0">
                        <div width="100%" height="32px" class="optiondatasty" v-html="upputSO[k].delta ? upputSO[k].delta : '0.0000'"></div>
                      </th>
                      <th v-if="gamacheckbox" colspan="1" class="text-center pa-0">
                        <div width="100%" height="32px" class="optiondatasty" v-html="upputSO[k].gamma ? upputSO[k].gamma : '0.0000'"></div>
                      </th>
                      <th v-if="vagacheckbox" colspan="1" class="text-center pa-0">
                        <div width="100%" height="32px" class="optiondatasty" v-html="upputSO[k].vega ? upputSO[k].vega : '0.0000'"></div>
                      </th>
                      <th v-if="thetacheckbox" colspan="1" class="text-center pa-0">
                        <div width="100%" height="32px" class="optiondatasty" v-html="upputSO[k].theta ? upputSO[k].theta : '0.0000'"></div>
                      </th>
                    </tr>
                    <tr v-if="chainSpotdata">
                      <th :colspan="opchtablehead" class="px-0">
                        <v-progress-linear value="100" color="#FF720C" height="1"></v-progress-linear>
                      </th>
                      <th id="opcenterview" colspan="4" class="text-center pa-0 opdatacalluphov">
                        <v-card class="white--text elevation-0" color="#FF720C" tile>
                          <span class="font-weight-bold body-2" v-html="chainSpotdata.lp ? chainSpotdata.lp : '0.00'"></span>
                        </v-card>
                      </th>
                      <th :colspan="opchtablehead" class="px-0">
                        <v-progress-linear value="100" color="#FF720C" height="1"></v-progress-linear>
                      </th>
                    </tr>
                    <tr class="totrhover" v-for="(script, k) in dwncallSO" :key="k + 'putoption'">
                      <th v-if="thetacheckbox" colspan="1" class="text-center pa-0">
                        <div width="100%" height="32px" class="optiondatasty" v-html="dwncallSO[k].theta ? dwncallSO[k].theta : '0.0000'"></div>
                      </th>
                      <th v-if="vagacheckbox" colspan="1" class="text-center pa-0">
                        <div width="100%" height="32px" class="optiondatasty" v-html="dwncallSO[k].vega ? dwncallSO[k].vega : '0.0000'"></div>
                      </th>
                      <th v-if="gamacheckbox" colspan="1" class="text-center pa-0">
                        <div width="100%" height="32px" class="optiondatasty" v-html="dwncallSO[k].gamma ? dwncallSO[k].gamma : '0.0000'"></div>
                      </th>
                      <th v-if="deltacheckbox" colspan="1" class="text-center pa-0">
                        <div width="100%" height="32px" class="optiondatasty" v-html="dwncallSO[k].delta ? dwncallSO[k].delta : '0.0000'"></div>
                      </th>
                      <th colspan="2" class="text-center opdatas">
                        <span class="optiondatasty" :id="`dwncall${dwncallSO[k].token}vol`" v-html="dwncallSO[k].vol ? dwncallSO[k].vol : '0.00'"></span>
                      </th>
                      <th colspan="2" class="text-center opdatas opdatacalluphov">
                        <div class="callprogress" :style="`--my-width-var:${dwncallSO[k].pro}%;`" :id="`dwncall${dwncallSO[k].token}pro`">
                          <!-- <div class="callprogress" :style="`--my-width-var:${(((dwncallSO[k].oi - barCallsOi) / barCallsOi) * 100 + 100)?.toFixed(0)}%;`"> -->
                          <span class="optiondatasty optionbartext">
                            <span :id="`dwncall${dwncallSO[k].token}oi`">{{ dwncallSO[k].oi ? dwncallSO[k].oi : "0.00" }}</span>
                            <span :id="`dwncall${dwncallSO[k].token}oiclr`" :style="`color:${dwncallSO[k].oich > 0 ? 'var(--maingreen)' : dwncallSO[k].oich < 0 ? 'var(--mainred)' : 'none'};`" class="optionchsty">
                              <span :id="`dwncall${dwncallSO[k].token}oich`">({{ dwncallSO[k].oich ? dwncallSO[k].oich : "0.00" }})</span>
                            </span></span
                          >
                        </div>
                      </th>
                      <th v-if="bitcheckbox" colspan="1" class="text-center opdatas">
                        <span class="optiondatasty" :id="`dwncall${dwncallSO[k].token}bid`" v-html="dwncallSO[k].bid ? dwncallSO[k].bid : '0.00'"></span>
                      </th>
                      <th v-if="askcheckbox" colspan="1" class="text-center opdatas">
                        <span class="optiondatasty" :id="`dwncall${dwncallSO[k].token}ask`" v-html="dwncallSO[k].ask ? dwncallSO[k].ask : '0.00'"></span>
                      </th>
                      <th colspan="2" class="text-center opdatas">
                        <span :style="`color:${dwncallSO[k].ch > 0 ? 'var(--maingreen)' : dwncallSO[k].ch < 0 ? 'var(--mainred)' : ''};`" :id="`dwncall${dwncallSO[k].token}chpclr`" class="optiondatasty"
                          ><span :id="`dwncall${dwncallSO[k].token}ch`">{{ dwncallSO[k].ch ? dwncallSO[k].ch : "0.00" }}</span
                          ><span :id="`dwncall${dwncallSO[k].token}chp`" class="optionchsty"> ({{ dwncallSO[k].chp ? dwncallSO[k].chp : "0.00" }}%)</span>
                        </span>
                      </th>
                      <th colspan="2" class="text-center opdatacalluphov opdatas">
                        <span class="optiondatasty" :id="`dwncall${dwncallSO[k].token}ltp`" v-html="Number(dwncallSO[k].ltp) ? dwncallSO[k].ltp : '0.00'"></span>
                        <tr class="opdatacallupbtn lfttrbtn" style="margin-left: -192px">
                          <th colspan="1" @click="chartOption(script)">
                            <div style="min-width: 32px; border-radius: 4px; border: thin solid var(--outline); padding: 1px 4px; background-color: var(--mainbg)" class="cursor-p mr-1">
                              <v-icon size="16" color="maintext">mdi-chart-line-variant</v-icon>
                            </div>
                          </th>
                          <th colspan="1" @click="basketOption(script)">
                            <div style="height: 22px; min-width: 32px; border-radius: 4px; border: thin solid var(--outline); padding: 1px 4px; background-color: var(--mainbg)" class="cursor-p mr-1">
                              <img width="16px" :src="require(`@/assets/usermenu/${$vuetify.theme.dark ? '9d' : '9'}.svg`)" />
                            </div>
                          </th>
                          <th colspan="1" @click="addOption(script)">
                            <div style="min-width: 32px; border-radius: 4px; border: thin solid var(--outline); padding: 1px 4px; background-color: var(--mainbg)" class="cursor-p mr-1">
                              <v-icon size="16" color="maintext">mdi-bookmark-outline</v-icon>
                            </div>
                          </th>
                          <th colspan="1" @click="sellOption(script)">
                            <div style="background-color: var(--mainred)" class="newhoverbtn newhoverbtnsize white--text cursor-p">S</div>
                          </th>
                          <th colspan="1" @click="buyOption(script)">
                            <div style="background-color: var(--maingreen)" class="newhoverbtn newhoverbtnsize white--text cursor-p">B</div>
                          </th>
                        </tr>
                      </th>
                      <th v-if="ivcheckbox" colspan="1" class="text-center pa-0">
                        <div width="100%" height="32px" class="optiondatasty" v-html="dwncallSO[k].IV ? dwncallSO[k].IV : '0.00'"></div>
                      </th>
                      <th colspan="4" class="text-center opstrprcborder opdatacalluphov pos-rlt">
                        <div class="lftbarstatus" :style="`--my-lftbrdclr-var:${dwncallSO[k].bar};`" :id="`dwncall${dwncallSO[k].token}bar`"></div>
                        <v-tooltip left color="black" v-if="dwncallSO[k].p">
                          <template v-slot:activator="{on, attrs}">
                            <v-icon v-bind="attrs" v-on="on" class="badghlpos pos-abs" :color="dwncallSO[k].pz" @click="$router.push('/positions')" size="14">{{ dwncallSO[k].icon }}</v-icon>
                          </template>
                          <span
                            ><b :class="dwncallSO[k].qtyclr">{{ dwncallSO[k].qtyp }}</b> Qty @ <span :class="dwncallSO[k].px" class="font-weight-bold">{{ dwncallSO[k].pmtm }}</span> MTM</span
                          >
                        </v-tooltip>
                        <span class="subtitle-2 font-weight-bold">{{ Number(dwncallSO[k].strprc) ? dwncallSO[k].strprc : "0.00" }}</span>
                        <v-tooltip right color="black" v-if="dwnputSO[k].p">
                          <template v-slot:activator="{on, attrs}">
                            <v-icon v-bind="attrs" v-on="on" class="badghrpos pos-abs" :color="dwnputSO[k].pz" @click="$router.push('/positions')" size="14">{{ dwnputSO[k].icon }}</v-icon>
                          </template>
                          <span
                            ><b :class="dwnputSO[k].qtyclr">{{ dwnputSO[k].qtyp }}</b> Qty @ <span :class="dwnputSO[k].px" class="font-weight-bold">{{ dwnputSO[k].pmtm }}</span> MTM</span
                          >
                        </v-tooltip>
                        <div class="rgtbarstatus" :style="`--my-rgtbrdclr-var:${dwnputSO[k].bar};`" :id="`dwnput${dwnputSO[k].token}bar`"></div>
                      </th>
                      <th v-if="ivcheckbox" colspan="1" class="text-center pa-0" :style="opdatabgs">
                        <div width="100%" height="32px" class="optiondatasty" v-html="dwnputSO[k].IV ? dwnputSO[k].IV : '0.00'"></div>
                      </th>
                      <th colspan="2" class="text-center opdatacalluphov opdatas" :style="opdatabgs">
                        <tr class="opdatacallupbtn dwnrghtrbtn">
                          <th colspan="1" @click="buyOption(script, dwnputSO)">
                            <div style="background-color: var(--maingreen)" class="newhoverbtn newhoverbtnsize white--text cursor-p">B</div>
                          </th>
                          <th colspan="1" @click="sellOption(script, dwnputSO)">
                            <div style="background-color: var(--mainred)" class="newhoverbtn newhoverbtnsize white--text cursor-p">S</div>
                          </th>
                          <th colspan="1" @click="addOption(script, dwnputSO)">
                            <div style="min-width: 32px; border-radius: 4px; border: thin solid var(--outline); padding: 1px 4px; background-color: var(--mainbg)" class="cursor-p">
                              <v-icon size="16" color="maintext">mdi-bookmark-outline</v-icon>
                            </div>
                          </th>
                          <th colspan="1" @click="basketOption(script, dwnputSO)">
                            <div style="height: 22px; min-width: 32px; border-radius: 4px; border: thin solid var(--outline); padding: 1px 4px; background-color: var(--mainbg)" class="cursor-p ml-1">
                              <img width="16px" :src="require(`@/assets/usermenu/${$vuetify.theme.dark ? '9d' : '9'}.svg`)" />
                            </div>
                          </th>
                          <th colspan="1" @click="chartOption(script, dwnputSO)">
                            <div style="min-width: 32px; border-radius: 4px; border: thin solid var(--outline); padding: 1px 4px; background-color: var(--mainbg)" class="cursor-p ml-1">
                              <v-icon size="16" color="maintext">mdi-chart-line-variant</v-icon>
                            </div>
                          </th>
                        </tr>
                        <span class="optiondatasty" :id="`dwnput${dwnputSO[k].token}ltp`" v-html="Number(dwnputSO[k].ltp) ? dwnputSO[k].ltp : '0.00'"></span>
                      </th>
                      <th colspan="2" class="text-center opdatas" :style="opdatabgs">
                        <span :style="`color:${dwnputSO[k].ch > 0 ? 'var(--maingreen)' : dwnputSO[k].ch < 0 ? 'var(--mainred)' : ''};`" :id="`dwnput${dwnputSO[k].token}chpclr`" class="optiondatasty"
                          ><span :id="`dwnput${dwnputSO[k].token}ch`">{{ dwnputSO[k].ch ? dwnputSO[k].ch : "0.00" }}</span
                          ><span :id="`dwnput${dwnputSO[k].token}chp`" class="optionchsty"> ({{ dwnputSO[k].chp ? dwnputSO[k].chp : "0.00" }}%)</span>
                        </span>
                      </th>
                      <th v-if="bitcheckbox" colspan="1" class="text-center pa-0" :style="opdatabgs">
                        <div width="100%" height="32px" class="optiondatasty" :id="`dwnput${dwnputSO[k].token}bid`" v-html="dwnputSO[k].bid ? dwnputSO[k].bid : '0.00'"></div>
                      </th>
                      <th v-if="askcheckbox" colspan="1" class="text-center pa-0" :style="opdatabgs">
                        <div width="100%" height="32px" class="optiondatasty" :id="`dwnput${dwnputSO[k].token}ask`" v-html="dwnputSO[k].ask ? dwnputSO[k].ask : '0.00'"></div>
                      </th>
                      <th colspan="2" class="text-center opdatas opdatacalluphov" :style="opdatabgs">
                        <div class="putprogress" :style="`--my-width-var:${dwnputSO[k].pro}%;`" :id="`dwnput${dwnputSO[k].token}pro`">
                          <!-- <div class="putprogress" :style="`--my-width-var:${(((dwnputSO[k].oi - barPutsOi) / barPutsOi) * 100 + 100)?.toFixed(0)}%;`"> -->
                          <span class="optiondatasty optionbartext">
                            <span :id="`dwnput${dwnputSO[k].token}oi`">{{ dwnputSO[k].oi ? dwnputSO[k].oi : "0.00" }}</span>
                            <span :id="`dwnput${dwnputSO[k].token}oiclr`" :style="`color:${dwnputSO[k].oich > 0 ? 'var(--maingreen)' : dwnputSO[k].oich < 0 ? 'var(--mainred)' : 'none'};`" class="optionchsty">
                              <span :id="`dwnput${dwnputSO[k].token}oich`">({{ dwnputSO[k].oich ? dwnputSO[k].oich : "0.00" }})</span>
                            </span></span
                          >
                        </div>
                      </th>
                      <th colspan="2" class="text-center opdatas" :style="opdatabgs">
                        <span class="optiondatasty" :id="`dwnput${dwnputSO[k].token}vol`" v-html="dwnputSO[k].vol ? dwnputSO[k].vol : '0.00'"></span>
                      </th>
                      <th v-if="deltacheckbox" colspan="1" class="text-center pa-0" :style="opdatabgs">
                        <div width="100%" height="32px" class="optiondatasty" v-html="dwnputSO[k].delt ? dwnputSO[k].delta : '0.0000'"></div>
                      </th>
                      <th v-if="gamacheckbox" colspan="1" class="text-center pa-0" :style="opdatabgs">
                        <div width="100%" height="32px" class="optiondatasty" v-html="dwnputSO[k].gamm ? dwnputSO[k].gamma : '0.0000'"></div>
                      </th>
                      <th v-if="vagacheckbox" colspan="1" class="text-center pa-0" :style="opdatabgs">
                        <div width="100%" height="32px" class="optiondatasty" v-html="dwnputSO[k].vega ? dwnputSO[k].vega : '0.0000'"></div>
                      </th>
                      <th v-if="thetacheckbox" colspan="1" class="text-center pa-0" :style="opdatabgs">
                        <div width="100%" height="32px" class="optiondatasty" v-html="dwnputSO[k].theta ? dwnputSO[k].theta : '0.0000'"></div>
                      </th>
                    </tr>
                  </tbody>
                  <tfoot class="mb-3">
                    <tr>
                      <th width="60px" v-if="thetacheckbox" colspan="1">
                        <v-card class="crd-trn elevation-0 text-center subtext--text" tile> </v-card>
                      </th>
                      <th width="60px" v-if="vagacheckbox" colspan="1">
                        <v-card class="crd-trn elevation-0 text-center subtext--text" tile> </v-card>
                      </th>
                      <th width="60px" v-if="gamacheckbox" colspan="1">
                        <v-card class="crd-trn elevation-0 text-center subtext--text" tile> </v-card>
                      </th>
                      <th width="60px" v-if="deltacheckbox" colspan="1">
                        <v-card class="crd-trn elevation-0 text-center subtext--text" tile> </v-card>
                      </th>
                      <th colspan="2">
                        <v-card class="crd-trn elevation-0 text-center subtext--text" tile> </v-card>
                      </th>
                      <th width="96px" colspan="2" class="px-0">
                        <v-card class="elevation-0 text-center" color="secbg" tile>
                          <span class="optionheadsty" v-html="pcrcallRatio > 0 ? pcrcallRatio : '0'"></span>
                        </v-card>
                      </th>
                      <th v-if="bitcheckbox" colspan="1">
                        <v-card class="crd-trn elevation-0 text-center subtext--text" tile> </v-card>
                      </th>
                      <th v-if="askcheckbox" colspan="1">
                        <v-card class="crd-trn elevation-0 text-center subtext--text" tile> </v-card>
                      </th>
                      <th width="100px" colspan="2">
                        <v-card class="crd-trn elevation-0 text-center subtext--text" tile> </v-card>
                      </th>
                      <th colspan="2">
                        <v-card class="crd-trn elevation-0 text-center subtext--text" tile> </v-card>
                      </th>
                      <th width="60px" v-if="ivcheckbox" colspan="1">
                        <v-card class="crd-trn elevation-0 text-center subtext--text" tile> </v-card>
                      </th>
                      <th colspan="4" style="border-left: thin solid rgba(0, 0, 0, 0.12); border-right: thin solid rgba(0, 0, 0, 0.12)">
                        <v-card class="crd-trn elevation-0 text-center" tile>
                          <span class="optionheadsty">PCR : {{ pcrRatio > 0 ? pcrRatio : "0.0000" }}</span>
                        </v-card>
                      </th>
                      <th width="60px" v-if="ivcheckbox" colspan="1">
                        <v-card class="crd-trn elevation-0 text-center subtext--text" tile> </v-card>
                      </th>
                      <th colspan="2">
                        <v-card class="crd-trn elevation-0 text-center subtext--text" tile> </v-card>
                      </th>
                      <th width="100px" colspan="2">
                        <v-card class="crd-trn elevation-0 text-center subtext--text" tile> </v-card>
                      </th>
                      <th v-if="bitcheckbox" colspan="1">
                        <v-card class="crd-trn elevation-0 text-center subtext--text" tile> </v-card>
                      </th>
                      <th v-if="askcheckbox" colspan="1">
                        <v-card class="crd-trn elevation-0 text-center subtext--text" tile> </v-card>
                      </th>
                      <th width="96px" colspan="2" class="px-0">
                        <v-card class="elevation-0 text-center" color="secbg" tile>
                          <span class="optionheadsty" v-html="pcrputRatio > 0 ? pcrputRatio : '0'"></span>
                        </v-card>
                      </th>
                      <th colspan="2">
                        <v-card class="crd-trn elevation-0 text-center subtext--text" tile> </v-card>
                      </th>
                      <th width="60px" v-if="deltacheckbox" colspan="1">
                        <v-card class="crd-trn elevation-0 text-center subtext--text" tile> </v-card>
                      </th>
                      <th width="60px" v-if="gamacheckbox" colspan="1">
                        <v-card class="crd-trn elevation-0 text-center subtext--text" tile> </v-card>
                      </th>
                      <th width="60px" v-if="vagacheckbox" colspan="1">
                        <v-card class="crd-trn elevation-0 text-center subtext--text" tile> </v-card>
                      </th>
                      <th width="60px" v-if="thetacheckbox" colspan="1">
                        <v-card class="crd-trn elevation-0 text-center subtext--text" tile> </v-card>
                      </th>
                    </tr>
                  </tfoot>
                </template>
              </v-simple-table>
              <v-navigation-drawer hide-overlay class="elevation-1" v-model="drawer" color="cardbg" absolute temporary right>
                <v-list-item class="text-right">
                  <p class="mt-2 mb-0">View setting</p>
                  <v-spacer></v-spacer>
                  <v-btn tile icon @click="drawer = false">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17 17" width="17" height="17" fill="currentColor">
                      <path d="m.58 1.42.82-.82 15 15-.82.82z"></path>
                      <path d="m.58 15.58 15-15 .82.82-15 15z"></path>
                    </svg>
                  </v-btn>
                </v-list-item>
                <v-divider></v-divider>
                <p class="ml-3 mt-2 mb-0">Add columns</p>
                <v-list-item>
                  <v-checkbox @change="simpleTablestyle()" v-model="bitcheckbox" label="BID"></v-checkbox>
                </v-list-item>
                <v-list-item>
                  <v-checkbox @change="simpleTablestyle()" v-model="askcheckbox" label="ASK"></v-checkbox>
                </v-list-item>
                <v-list-item>
                  <v-checkbox @change="simpleTablestyle()" v-model="ivcheckbox" label="IV"></v-checkbox>
                </v-list-item>
                <v-divider></v-divider>
                <p class="ml-3 mt-2 mb-0">Add greeks</p>
                <v-list-item>
                  <v-checkbox @change="simpleTablestyle()" v-model="deltacheckbox" label="DELTA"></v-checkbox>
                </v-list-item>
                <v-list-item>
                  <v-checkbox @change="simpleTablestyle()" v-model="gamacheckbox" label="GAMA"></v-checkbox>
                </v-list-item>
                <v-list-item>
                  <v-checkbox @change="simpleTablestyle()" v-model="vagacheckbox" label="VEGA"></v-checkbox>
                </v-list-item>
                <v-list-item>
                  <v-checkbox @change="simpleTablestyle()" v-model="thetacheckbox" label="THETA"></v-checkbox>
                </v-list-item>
                <v-divider></v-divider>
                <p class="ml-3 mt-2 mb-0">Info</p>
                <v-list-item>
                  <div style="background-color: #1e53e530" class="infoprogress"></div>
                  <v-list-item-content class="ml-2">
                    <v-list-item-title>OI percentage bar</v-list-item-title>
                    <v-list-item-subtitle
                      >size diff from max <br />
                      call & put</v-list-item-subtitle
                    >
                  </v-list-item-content>
                </v-list-item>
                <v-divider class="mx-4"></v-divider>
                <p class="ml-3 mt-2 mb-0">Bullish</p>
                <v-list-item>
                  <div style="background-color: var(--maingreen)" class="infobarstatus"></div>
                  <v-list-item-content class="ml-2">
                    <v-list-item-title>Up trend (Long build-up)</v-list-item-title>
                    <v-list-item-subtitle>More traders are buying</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <div style="background-color: var(--secgreen)" class="infobarstatus"></div>
                  <v-list-item-content class="ml-2">
                    <v-list-item-title>Down trend (Short covering)</v-list-item-title>
                    <v-list-item-subtitle>Buyers are squaring</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-divider class="mx-4"></v-divider>

                <p class="ml-3 mt-2 mb-0">Bearish</p>
                <v-list-item>
                  <div style="background-color: var(--mainred)" class="infobarstatus"></div>
                  <v-list-item-content class="ml-2">
                    <v-list-item-title>Down trend (Short build-up)</v-list-item-title>
                    <v-list-item-subtitle>More traders are selling</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <div style="background-color: var(--secred)" class="infobarstatus"></div>
                  <v-list-item-content class="ml-2">
                    <v-list-item-title>Down trend (Long unwinding)</v-list-item-title>
                    <v-list-item-subtitle>Profit booking</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-divider class="mx-4"></v-divider>
                <v-list-item>
                  <div style="background-color: rgba(0, 0, 0, 0.12)" class="infobarstatus"></div>
                  <v-list-item-content class="ml-2">
                    <v-list-item-title>Trade not accurate</v-list-item-title>
                    <v-list-item-subtitle>none</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-navigation-drawer>
            </v-card>
            <v-card color="transparent" v-if="coractloader" width="100%" style="z-index: 0 !important" class="elevation-0 rounded-lg py-10 px-16">
              <p class="pa-16 pb-0 text-center">Getting your Option Chain</p>
              <v-card width="200px" class="elevation-0 mx-auto">
                <v-progress-linear class="mb-12" color="primary" indeterminate rounded height="6"></v-progress-linear>
              </v-card>
            </v-card>
          </div>
        </div>
      </v-card>
    </div>
    <div v-else class="no-scroll pos-rlt" style="height: calc(100vh - 162px)">
      <div class="pos-cent">
        <p class="mb-0 fs-14 maintext--text font-weight-medium text-center">
          No Option chain data <br />
          for <b>{{ optionStockSymbol ? optionStockSymbol : "" }}</b>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import eventBus from "../../../eventBus.js";
import {getMasters, getQuotesdata, getOptionschain, getGreekoptions, getMPosotion} from "@/components/mixins/getAPIdata";
// import postD from "../../../../postD.json";
export default {
  data() {
    return {
      pcrRatio: 0.0,
      pcrputRatio: 0.0,
      pcrcallRatio: 0.0,

      greekCount: 0,
      drawer: false,
      opchtablehead: 10,
      daydiff: null,

      simtblwidth: "100%",
      simtblscroll: "hidden",
      bitcheckbox: true,
      askcheckbox: true,
      ivcheckbox: false,
      thetacheckbox: false,
      vagacheckbox: false,
      gamacheckbox: false,
      deltacheckbox: false,
      opdatabgs: "background-color: #F9FCFF !important;",
      optionStockSymbol: "",
      optionStockSpot: "",
      optionStockSymbolInfo: {},
      // futureStockSymbolInfo: {},

      coractdata: false,
      coractloader: true,

      optionchainid: true,

      userid: "",
      usession: "",

      exchange: "",
      scriptToken: "",
      chainStocksList: [],
      subscriptionchainStocksList: [],

      ccfilter: 1,
      chainCount: null,
      optionStockName: "",
      securityinfo: [],
      opname: "",
      chainSpotdata: {},
      chainSpotPrice: {},
      linkedscrips: [],
      lsexd: [],
      tsym: [],
      optionchain: [],
      lsexdfilter: 0,
      lsexdval: "",
      opexch: "",
      data1: [],
      callsideopc: [],
      putsideopc: [],
      barCallsOi: null,
      barPutsOi: null,

      dwncallSO: [],
      dwnputSO: [],
      upcallSO: [],
      upputSO: [],

      optchainbasketdata: [],
      positiondata: [],
    };
  },
  async created() {
    this.userid = sessionStorage.getItem("userid");
    this.usession = sessionStorage.getItem("msession");
    this.opdatabgs = `background-color: ${this.$vuetify.theme.dark ? "#1E222D" : "#F9FCFF"} !important;`;
  },

  mounted() {
    let local = localStorage.getItem("ssdtsym");
    if (local && local.includes(":")) {
      this.setWaiting(local);
    }

    eventBus.$on("ssd-event", (type, token, exch, tsym) => {
      // this.optionStockSymbol = localStorage.getItem('ssdtsym');
      // this.optionchainMet('exit');
      this.optionStockSymbol = tsym;
      this.setWebsocket("unsub-D", this.chainStocksList, "ssd");
      this.clearOption(true);
      this.coractloader = true;
      this.coractdata = false;
      this.setWaiting(`${exch}:${tsym}`, "exit");
    });

    eventBus.$on("web-scoketConn", (data, page) => {
      if (page == "stockSSD" && this.chainStocksList) {
        this.optionChainDataParse(data);
      }
    });
    eventBus.$on("orderbook-update", (book) => {
      if (book == "port-order") {
        this.getPositionbook();
      }
    });
  },
  beforeDestroy() {
    // eventBus.$off('ssd-event');
    // eventBus.$off('web-scoketConn');
    eventBus.$off("orderbook-update");
  },

  methods: {
    async setWaiting(tsym, exit) {
      var token = localStorage.getItem("ssdtoken");
      var windata = window.ssdreqdata.data;
      var opt;
      if ((windata && windata[token] && windata[token].l) || (window.ssddetail[0] && window.ssddetail[0].token == token)) {
        opt = windata[token] && windata[token].l ? windata[token].l : window.ssddetail[2];
        let sym = tsym.toUpperCase();
        if (sym.split(":")[0].match(/^NFO$/)) {
          this.optionStockSymbol = sym;
        } else {
          let matchval = sym.match(/(\d{1,2})[a-zA-Z]{3}(\d{2,4})?/g);
          if (matchval) {
            let scriptList = sym.split(matchval[0]);

            if (scriptList[0].split(":")[1].match(/^NIFTY$/)) {
              this.optionStockSymbol = "NSE:NIFTY 50";
            } else if (scriptList[0].split(":")[1].match(/^BANKNIFTY$/)) {
              this.optionStockSymbol = "NSE:NIFTY BANK";
            } else if (scriptList[0].split(":")[1].match(/^FINNIFTY$/)) {
              this.optionStockSymbol = "NSE:NIFTY FIN SERVICE";
            } else if (scriptList[0].split(":")[0].match(/^NFO$/)) {
              this.optionStockSymbol = `NSE:${scriptList[0].split(":")[1]}-EQ`;
            } else {
              this.optionStockSymbol = sym; //`${scriptList[0]}${matchval[0]}`;
            }
          } else {
            this.optionStockSymbol = sym;
          }
        }
        let symbols = await getMasters(`${this.optionStockSymbol}`);
        if (symbols && opt.opt_exp && opt.opt_exp.length > 0 && ((windata[token] && windata[token].q) || window.ssddetail[0])) {
          this.optionStockSpot = 0;
          this.optionchainMet(exit ? exit : null, opt, token);
          this.optionchainid = true;
          this.coractloader = true;
          this.coractdata = false;
        } else {
          this.clearOption();
          this.optionStockSymbol = sym;
        }
      } else if (opt && opt.opt_exp == []) {
        this.clearOption();
      } else {
        setTimeout(() => {
          this.setWaiting(tsym, exit);
        }, 100);
      }
    },
    clearOption(flow) {
      if (!flow) {
        this.optionchainid = false;
      }
      this.chainStocksList = [];
      this.greekCount = 0;
      this.chainSpotdata = {};
      this.lsexd = [];
      this.upcallSO = [];
      this.upputSO = [];
      this.dwncallSO = [];
      this.dwnputSO = [];
      this.daydiff = "";
      this.barCallsOi = null;
      this.barPutsOi = null;

      this.callsideopc = [];
      this.putsideopc = [];
      this.data1 = [];
      this.optionchain = [];
      this.chainSpotPrice = {};
      this.linkedscrips = [];
      this.dwncallSO = [];
      this.dwnputSO = [];
      this.upcallSO = [];
      this.upputSO = [];
      this.optionStockSymbolInfo = {};
      // this.futureStockSymbolInfo = {};
      this.subscriptionchainStocksList = [];
    },
    async optionchainMet(exit, windata, tokenis) {
      if (exit == "exit") {
        this.coractloader = true;
        this.coractdata = false;
      } else {
        this.opdatstrue = JSON.parse(localStorage.getItem(`${this.uid}_opdatstrue`));
        this.opvalstrue = JSON.parse(localStorage.getItem(`${this.uid}_opvalstrue`));

        this.drawer = false;
        if (this.opdatstrue != null) {
          this.ivcheckbox = this.opdatstrue.ivcheckbox;
          this.vagacheckbox = this.opdatstrue.vagacheckbox;
          this.thetacheckbox = this.opdatstrue.thetacheckbox;
          this.gamacheckbox = this.opdatstrue.gamacheckbox;
          this.deltacheckbox = this.opdatstrue.deltacheckbox;
          this.defltTablestyle();
        } else {
          this.ivcheckbox = false;
          this.vagacheckbox = false;
          this.thetacheckbox = false;
          this.gamacheckbox = false;
          this.deltacheckbox = false;
        }
        this.bitcheckbox = true;
        this.askcheckbox = true;
      }
      this.chainCount = "";
      this.chainSpotdata = {};
      this.lsexd = [];
      this.upcallSO = [];
      this.upputSO = [];
      this.dwncallSO = [];
      this.dwnputSO = [];

      let symbols = await getMasters(`${this.optionStockSymbol}`);
      if (symbols) {
        this.exchange = this.optionStockSymbol.split(":")[0];
        this.scriptToken = symbols[0];

        var winssd = window.ssdreqdata.data[tokenis] ? window.ssdreqdata.data[tokenis].i : window.ssddetail[3];
        var winqut = window.ssdreqdata.data[tokenis] ? window.ssdreqdata.data[tokenis].q : window.ssddetail[0];

        if (winssd) {
          this.securityinfo = winssd;
          this.opname = winssd.symname;
          this.opexch = winssd.exch;
          this.optoken = winssd.token;
          this.linkedscrips = windata;
          this.data1 = windata.opt_exp;
          let data1 = windata.opt_exp;
          data1.sort((a, b) => new Date(a.exd) - new Date(b.exd));
          for (let i = 0; i < data1.length; i++) {
            this.lsexd.push(data1[i].exd);
            this.tsym.push(data1[i].tsym);
          }
          this.lsexdval = this.lsexd[0];
          this.chainCount = "10";

          // if (windata.fut.length > 0) {
          //   let futsymbol = windata.fut;
          //   futsymbol.sort((a, b) => new Date(a.exd) - new Date(b.exd));
          //   let futsymbols = await getMasters(`${futsymbol[0].exch}:${futsymbol[0].tsym}`);
          //   this.futureStockSymbolInfo = {
          //     exch: futsymbol[0].exch,
          //     token: futsymbols[0],
          //     tsym: futsymbol[0].tsym,
          //   };
          // } else {
          //   this.futureStockSymbolInfo = {};
          // }
          var qu;
          if (winqut.und_exch && winqut.und_tk) {
            qu = await getQuotesdata(`${winqut.und_exch}|${winqut.und_tk}`);
          } else {
            qu = winqut;
          }
          if (qu) {
            this.subscriptionchainStocksList.push({
              exch: qu.exch,
              token: qu.token,
              tsym: qu.tsym,
            });
            this.optionStockSymbolInfo = {
              exch: qu.exch,
              token: qu.token,
              tsym: qu.tsym,
            };
            this.optionStockName = this.optionStockSymbolInfo.tsym;
            this.optionStockSpot = qu.lp;
            this.optionChainDate();
          }
          // }
        } else {
          eventBus.$emit("snack-event", 0, "The symbol has no tradable futures or options.");
        }
      }
    },
    async optionChainDate() {
      this.greekCount = 0;
      this.daydiff = "";
      this.chainStocksList = [];
      this.upcallSO = [];
      this.dwncallSO = [];
      this.chainSpotPrice = this.optionStockSpot;
      this.lsexdval = this.lsexd[this.lsexdfilter];
      let ccc = ["5", "10", "15", "30", "50"];
      this.chainCount = ccc[this.ccfilter];
      let result = this.data1.find((item) => item.exd === this.lsexdval);
      if (this.lsexdval) {
        let fromdate = new Date(this.lsexdval);
        let fromdateof = (fromdate.getMonth() > 8 ? fromdate.getMonth() + 1 : "0" + (fromdate.getMonth() + 1)) + "/" + (fromdate.getDate() > 9 ? fromdate.getDate() : "0" + fromdate.getDate()) + "/" + fromdate.getFullYear();
        let todate = new Date();
        let todateof = (todate.getMonth() > 8 ? todate.getMonth() + 1 : "0" + (todate.getMonth() + 1)) + "/" + (todate.getDate() > 9 ? todate.getDate() : "0" + todate.getDate()) + "/" + todate.getFullYear();
        let d1 = new Date(todateof);
        let d2 = new Date(fromdateof);
        let diff = d2.getTime() - d1.getTime();
        let bfodaydiff = diff / (1000 * 60 * 60 * 24);
        this.daydiff = bfodaydiff + 1;
      }

      let ocdata = await getOptionschain(`jData={"uid":"${this.userid}","exch":"${result.exch}","tsym":"${result.tsym.includes("&") ? result.tsym.replace("&", "%26") : result.tsym}","cnt":"${this.chainCount}","strprc":"${this.optionStockSpot}"}&jKey=${this.usession}`);

      if (ocdata.stat == "Ok") {
        this.optionchain = ocdata;
        this.chainStocksList = ocdata.values;
        this.subscriptionchainStocksList = ocdata.values;

        let myArray;
        var cc = Number(this.chainCount);
        var scc = cc * 2;

        myArray = this.chainStocksList.sort((a, b) => {
          return a.strprc - b.strprc;
        });

        this.callsideopc = myArray
          .filter((arr) => {
            return arr.optt == "CE";
          })
          .slice(0, scc);
        this.putsideopc = myArray
          .filter((arr) => {
            return arr.optt == "PE";
          })
          .slice(0, scc);

        this.upcallSO = [
          ...myArray
            .filter((arr) => {
              arr["p"] = "";
              return arr.optt == "CE";
            })
            .slice(0, cc),
        ];
        this.upputSO = [
          ...myArray
            .filter((arr) => {
              arr["p"] = "";
              return arr.optt == "PE";
            })
            .slice(0, cc),
        ];

        this.dwncallSO = [
          ...myArray
            .filter((arr) => {
              arr["p"] = "";
              return arr.optt == "CE";
            })
            .slice(cc, scc),
        ];
        this.dwnputSO = [
          ...myArray
            .filter((arr) => {
              arr["p"] = "";
              return arr.optt == "PE";
            })
            .slice(cc, scc),
        ];
        await this.getPositionbook();

        this.setWebsocket("sub", this.subscriptionchainStocksList, "ssd");
        this.coractdata = true;
        this.coractloader = false;
      } else {
        eventBus.$emit("snack-event", 0, ocdata);
      }
    },
    setWebsocket(flow, data, is) {
      eventBus.$emit("web-scoketOn", flow, data, is, "stockSSD");
    },
    optionChainDataParse(data) {
      if (this.optionStockSymbolInfo.token == data.token) {
        this.chainSpotdata = data;
      }

      let upci = this.upcallSO.findIndex((script) => script.token == data.token);
      let uppi = this.upputSO.findIndex((script) => script.token == data.token);
      let dwnci = this.dwncallSO.findIndex((script) => script.token == data.token);
      let dwnpi = this.dwnputSO.findIndex((script) => script.token == data.token);
      if (upci >= 0) {
        this.upcallSO[upci].ltp = Number(data.lp) ? Number(data.lp).toFixed(2) : data.lp;
        this.upcallSO[upci].ask = Number(data.ask) ? Number(data.ask).toFixed(2) : data.ask;
        this.upcallSO[upci].bid = Number(data.bid) ? Number(data.bid).toFixed(2) : data.ask;
        this.upcallSO[upci].ch = Number(data.ch) ? Number(data.ch).toFixed(2) : data.ch;
        this.upcallSO[upci].chp = Number(data.chp) ? Number(data.chp).toFixed(2) : data.chp;
        this.upcallSO[upci].coi = Number(data.oi) ? Number(data.oi).toFixed(2) : data.oi;
        this.upcallSO[upci].oi = (data.oi / 100000).toFixed(2);
        this.upcallSO[upci].oich = ((data.oi - data.poi) / 100000).toFixed(2);
        this.upcallSO[upci].pro = (((data.oi - this.barCallsOi) / this.barCallsOi) * 100 + 100)?.toFixed(0);
        this.upcallSO[upci].vol = (data.socketVolume / 100000)?.toFixed(2);

        this.upcallSO[upci].bar =
          data.ch > 0 && this.upcallSO[upci].oich > 0 ? "var(--maingreen);" : data.ch < 0 && this.upcallSO[upci].oich > 0 ? "var(--mainred)" : data.ch > 0 && this.upcallSO[upci].oich < 0 ? "var(--secgreen)" : data.ch < 0 && this.upcallSO[upci].oich < 0 ? "var(--secred)" : "rgba(0, 0, 0, 0.12)";
        if (this.upcallSO[upci].p) {
          var p = this.upcallSO[upci].ppp;
          // this.upcallSO[upci]["prpnl"] = ((Number(data.lp) - Number(p.avgprc)) * Number(p.netqty)).toFixed(2);
          this.upcallSO[upci]["pmtm"] = (((Number(data.lp) - Number(p.netavgprc)) * Number(p.netqty)) + Number(p.crpnl)).toFixed(2);
          this.upcallSO[upci]["px"] = this.upcallSO[upci].pmtm > 0 ? "maingreen--text" : this.upcallSO[upci].pmtm < 0 ? "mainred--text" : "white--text";
          this.upcallSO[upci]["pz"] = this.upcallSO[upci].pmtm > 0 ? "maingreen" : this.upcallSO[upci].pmtm < 0 ? "mainred" : "rgba(0, 0, 0, 0.12)";
          this.upcallSO[upci]["icon"] = this.upcallSO[upci].pmtm > 0 ? "mdi-arrow-up-drop-circle" : this.upcallSO[upci].pmtm < 0 ? "mdi-arrow-down-drop-circle" : "mdi-minus-circle";
        }
        this.setOptionchainvalue("upcall", data.token, this.upcallSO[upci]);
      } else if (uppi >= 0) {
        this.upputSO[uppi].ltp = Number(data.lp) ? Number(data.lp).toFixed(2) : data.lp;
        this.upputSO[uppi].ask = Number(data.ask) ? Number(data.ask).toFixed(2) : data.ask;
        this.upputSO[uppi].bid = Number(data.bid) ? Number(data.bid).toFixed(2) : data.ask;
        this.upputSO[uppi].ch = Number(data.ch) ? Number(data.ch).toFixed(2) : data.ch;
        this.upputSO[uppi].chp = Number(data.chp) ? Number(data.chp).toFixed(2) : data.chp;
        this.upputSO[uppi].coi = Number(data.oi) ? Number(data.oi).toFixed(2) : data.oi;
        this.upputSO[uppi].oi = (data.oi / 100000).toFixed(2);
        this.upputSO[uppi].oich = ((data.oi - data.poi) / 100000).toFixed(2);
        this.upputSO[uppi].pro = (((data.oi - this.barPutsOi) / this.barPutsOi) * 100 + 100)?.toFixed(0);
        this.upputSO[uppi].vol = (data.socketVolume / 100000)?.toFixed(2);

        this.upputSO[uppi].bar =
          data.ch > 0 && this.upputSO[uppi].oich > 0 ? "var(--maingreen);" : data.ch < 0 && this.upputSO[uppi].oich > 0 ? "var(--mainred)" : data.ch > 0 && this.upputSO[uppi].oich < 0 ? "var(--secgreen)" : data.ch < 0 && this.upputSO[uppi].oich < 0 ? "var(--secred)" : "rgba(0, 0, 0, 0.12)";
        if (this.upputSO[uppi].p) {
          var pr = this.upputSO[uppi].ppp;
          // this.upputSO[uppi]["prpnl"] = ((Number(data.lp) - Number(pr.avgprc)) * Number(pr.netqty)).toFixed(2);
          this.upputSO[uppi]["pmtm"] = (((Number(data.lp) - Number(pr.netavgprc)) * Number(pr.netqty)) + Number(pr.crpnl)).toFixed(2);
          this.upputSO[uppi]["px"] = this.upputSO[uppi].pmtm > 0 ? "maingreen--text" : this.upputSO[uppi].pmtm < 0 ? "mainred--text" : "white--text";
          this.upputSO[uppi]["pz"] = this.upputSO[uppi].pmtm > 0 ? "maingreen" : this.upputSO[uppi].pmtm < 0 ? "mainred" : "rgba(0, 0, 0, 0.12)";
          this.upputSO[uppi]["icon"] = this.upputSO[uppi].pmtm > 0 ? "mdi-arrow-up-drop-circle" : this.upputSO[uppi].pmtm < 0 ? "mdi-arrow-down-drop-circle" : "mdi-minus-circle";
        }
        this.setOptionchainvalue("upput", data.token, this.upputSO[uppi]);
      } else if (dwnci >= 0) {
        this.dwncallSO[dwnci].ltp = Number(data.lp) ? Number(data.lp).toFixed(2) : data.lp;
        this.dwncallSO[dwnci].ask = Number(data.ask) ? Number(data.ask).toFixed(2) : data.ask;
        this.dwncallSO[dwnci].bid = Number(data.bid) ? Number(data.bid).toFixed(2) : data.ask;
        this.dwncallSO[dwnci].ch = Number(data.ch) ? Number(data.ch).toFixed(2) : data.ch;
        this.dwncallSO[dwnci].chp = Number(data.chp) ? Number(data.chp).toFixed(2) : data.chp;
        this.dwncallSO[dwnci].coi = Number(data.oi) ? Number(data.oi).toFixed(2) : data.oi;
        this.dwncallSO[dwnci].oi = (data.oi / 100000).toFixed(2);
        this.dwncallSO[dwnci].oich = ((data.oi - data.poi) / 100000).toFixed(2);
        this.dwncallSO[dwnci].pro = (((data.oi - this.barCallsOi) / this.barCallsOi) * 100 + 100)?.toFixed(0);
        this.dwncallSO[dwnci].vol = (data.socketVolume / 100000)?.toFixed(2);

        this.dwncallSO[dwnci].bar =
          data.ch > 0 && this.dwncallSO[dwnci].oich > 0
            ? "var(--maingreen);"
            : data.ch < 0 && this.dwncallSO[dwnci].oich > 0
            ? "var(--mainred)"
            : data.ch > 0 && this.dwncallSO[dwnci].oich < 0
            ? "var(--secgreen)"
            : data.ch < 0 && this.dwncallSO[dwnci].oich < 0
            ? "var(--secred)"
            : "rgba(0, 0, 0, 0.12)";
        if (this.dwncallSO[dwnci].p) {
          var po = this.dwncallSO[dwnci].ppp;
          // this.dwncallSO[dwnci]["prpnl"] = ((Number(data.lp) - Number(po.avgprc)) * Number(po.netqty)).toFixed(2);
          this.dwncallSO[dwnci]["pmtm"] = (((Number(data.lp) - Number(po.netavgprc)) * Number(po.netqty)) + Number(po.crpnl)).toFixed(2);
          this.dwncallSO[dwnci]["px"] = this.dwncallSO[dwnci].pmtm > 0 ? "maingreen--text" : this.dwncallSO[dwnci].pmtm < 0 ? "mainred--text" : "white--text";
          this.dwncallSO[dwnci]["pz"] = this.dwncallSO[dwnci].pmtm > 0 ? "maingreen" : this.dwncallSO[dwnci].pmtm < 0 ? "mainred" : "rgba(0, 0, 0, 0.12)";
          this.dwncallSO[dwnci]["icon"] = this.dwncallSO[dwnci].pmtm > 0 ? "mdi-arrow-up-drop-circle" : this.dwncallSO[dwnci].pmtm < 0 ? "mdi-arrow-down-drop-circle" : "mdi-minus-circle";
        }
        this.setOptionchainvalue("dwncall", data.token, this.dwncallSO[dwnci]);
      } else if (dwnpi >= 0) {
        this.dwnputSO[dwnpi].ltp = Number(data.lp) ? Number(data.lp).toFixed(2) : data.lp;
        this.dwnputSO[dwnpi].ask = Number(data.ask) ? Number(data.ask).toFixed(2) : data.ask;
        this.dwnputSO[dwnpi].bid = Number(data.bid) ? Number(data.bid).toFixed(2) : data.ask;
        this.dwnputSO[dwnpi].ch = Number(data.ch) ? Number(data.ch).toFixed(2) : data.ch;
        this.dwnputSO[dwnpi].chp = Number(data.chp) ? Number(data.chp).toFixed(2) : data.chp;
        this.dwnputSO[dwnpi].coi = Number(data.oi) ? Number(data.oi).toFixed(2) : data.oi;
        this.dwnputSO[dwnpi].oi = (data.oi / 100000).toFixed(2);
        this.dwnputSO[dwnpi].oich = ((data.oi - data.poi) / 100000).toFixed(2);
        this.dwnputSO[dwnpi].pro = (((data.oi - this.barPutsOi) / this.barPutsOi) * 100 + 100)?.toFixed(0);
        this.dwnputSO[dwnpi].vol = (data.socketVolume / 100000)?.toFixed(2);

        this.dwnputSO[dwnpi].bar =
          data.ch > 0 && this.dwnputSO[dwnpi].oich > 0 ? "var(--maingreen);" : data.ch < 0 && this.dwnputSO[dwnpi].oich > 0 ? "var(--mainred)" : data.ch > 0 && this.dwnputSO[dwnpi].oich < 0 ? "var(--secgreen)" : data.ch < 0 && this.dwnputSO[dwnpi].oich < 0 ? "var(--secred)" : "rgba(0, 0, 0, 0.12)";
        if (this.dwnputSO[dwnpi].p) {
          var pt = this.dwnputSO[dwnpi].ppp;
          // this.dwnputSO[dwnpi]["prpnl"] = ((Number(data.lp) - Number(pt.avgprc)) * Number(pt.netqty)).toFixed(2);
          this.dwnputSO[dwnpi]["pmtm"] = (((Number(data.lp) - Number(pt.netavgprc)) * Number(pt.netqty)) + Number(pt.crpnl)).toFixed(2);
          this.dwnputSO[dwnpi]["px"] = this.dwnputSO[dwnpi].pmtm > 0 ? "maingreen--text" : this.dwnputSO[dwnpi].pmtm < 0 ? "mainred--text" : "white--text";
          this.dwnputSO[dwnpi]["pz"] = this.dwnputSO[dwnpi].pmtm > 0 ? "maingreen" : this.dwnputSO[dwnpi].pmtm < 0 ? "mainred" : "rgba(0, 0, 0, 0.12)";
          this.dwnputSO[dwnpi]["icon"] = this.dwnputSO[dwnpi].pmtm > 0 ? "mdi-arrow-up-drop-circle" : this.dwnputSO[dwnpi].pmtm < 0 ? "mdi-arrow-down-drop-circle" : "mdi-minus-circle";
        }
        this.setOptionchainvalue("dwnput", data.token, this.dwnputSO[dwnpi]);
      }
      this.barCallsOi = Math.max(...this.callsideopc.map((o) => o.coi));
      this.barPutsOi = Math.max(...this.putsideopc.map((o) => o.coi));

      this.pcrRatio = (this.putsideopc.reduce((v1, v2) => v1 + parseFloat(v2.coi), 0) / this.callsideopc.reduce((v1, v2) => v1 + parseFloat(v2.coi), 0))?.toFixed(4);
      this.pcrputRatio = this.putsideopc.reduce((v1, v2) => v1 + parseFloat(v2.coi), 0);
      this.pcrcallRatio = this.callsideopc.reduce((v1, v2) => v1 + parseFloat(v2.coi), 0);

      if (this.ivcheckbox || this.vagacheckbox || this.thetacheckbox || this.gamacheckbox || this.deltacheckbox) {
        if (dwnci >= 0 && this.dwncallSO[dwnci] && !("IV" in this.dwncallSO[dwnci])) {
          this.getGreekValues("dwncallSO", this.dwncallSO, dwnci);
        }
        if (dwnpi >= 0 && this.dwnputSO[dwnpi] && !("IV" in this.dwnputSO[dwnpi])) {
          this.getGreekValues("dwnputSO", this.dwnputSO, dwnpi);
        }
        if (upci >= 0 && this.upcallSO[upci] && !("IV" in this.upcallSO[upci])) {
          this.getGreekValues("upcallSO", this.upcallSO, upci);
        }
        if (uppi >= 0 && this.upputSO[uppi] && !("IV" in this.upputSO[uppi])) {
          this.getGreekValues("upputSO", this.upputSO, uppi);
        }
      }
    },
    setOptionchainvalue(k, t, d) {
      // dwnput dwncall upput upcall
      let tag = document.getElementById(`${k}${t}ltp`);
      if (tag) {
        document.getElementById(`${k}${t}ltp`).innerHTML = d.ltp;
        document.getElementById(`${k}${t}ch`).innerHTML = d.ch;
        document.getElementById(`${k}${t}chp`).innerHTML = ` (${d.chp}%)`;
        document.getElementById(`${k}${t}oi`).innerHTML = d.oi;
        document.getElementById(`${k}${t}oich`).innerHTML = ` (${d.oich})`;
        document.getElementById(`${k}${t}vol`).innerHTML = d.vol;
        eventBus.$emit("color-event", `${k}${t}oiclr`, d.oich > 0 ? "maingreen--text" : d.oich < 0 ? "mainred--text" : "none");
        eventBus.$emit("color-event", `${k}${t}chpclr`, d.ch > 0 ? "maingreen--text" : d.ch < 0 ? "mainred--text" : "none");

        var prop = k == "upput" || k == "dwnput" ? "--my-rgtbrdclr-var" : "--my-lftbrdclr-var";
        document.getElementById(`${k}${t}bar`).style.setProperty(prop, d.bar);
      }
      let tagb = document.getElementById(`${k}${t}bid`);
      if (tagb) {
        document.getElementById(`${k}${t}bid`).innerHTML = d.bid;
      }
      let taga = document.getElementById(`${k}${t}ask`);
      if (taga) {
        document.getElementById(`${k}${t}ask`).innerHTML = d.ask;
      }
    },
    async getGreekValues(section, optionData, index) {
      if (this.greekCount < parseInt(this.chainCount) * 4) {
        this.greekCount += 1;

        let greek = await getGreekoptions(
          JSON.stringify({
            spotPrice: this.optionStockSpot,
            expiryDay: parseInt(this.daydiff),
            OPTIONS: optionData[index],
          })
        );

        let update = section == "dwncallSO" ? this.dwncallSO[index] : section == "dwnputSO" ? this.dwnputSO[index] : section == "upcallSO" ? this.upcallSO[index] : section == "upputSO" ? this.upputSO[index] : {};

        if (greek && "GreekValues" in greek && greek["GreekValues"].length > 0 && update) {
          greek = greek["GreekValues"][0];
          update.IV = Number(greek.IV) ? Number(greek.IV).toFixed(2) : greek.IV;
          update.Rho = greek.Rho;
          update.delta = Number(greek.delta) ? Number(greek.delta).toFixed(4) : greek.delta;
          update.gamma = Number(greek.gamma) ? Number(greek.gamma).toFixed(4) : greek.gamma;
          update.theta = Number(greek.theta) ? Number(greek.theta).toFixed(4) : greek.theta;
          update.vega = Number(greek.vega) ? Number(greek.vega).toFixed(4) : greek.vega;
          this.$set(section == "dwncallSO" ? this.dwncallSO : section == "dwnputSO" ? this.dwnputSO : section == "upcallSO" ? this.upcallSO : section == "upputSO" ? this.upputSO : {}, index, update);
        }
      }
    },
    basketOption(script, dwnputSO, upputSO) {
      let bak;
      if (typeof dwnputSO == "object") {
        bak = dwnputSO.find((item) => item.strprc === script.strprc);
      } else if (typeof upputSO == "object") {
        bak = upputSO.find((item) => item.strprc === script.strprc);
      } else {
        bak = script;
      }
      if (bak) {
        bak["buySell"] = "BUY";
        bak["expdate"] = this.lsexd;
        bak["ordvai"] = "MKT";
        bak["ordlot"] = 1;
        bak["ordprc"] = Number(bak.ltp);
        bak["checkbox"] = true;

        eventBus.$emit("bskwatch-event", "add", bak, this.optionchain);
      }
    },
    buyOption(script, dwnputSO, upputSO) {
      let buy;
      if (typeof dwnputSO == "object") {
        buy = dwnputSO.find((item) => item.strprc === script.strprc);
      } else if (typeof upputSO == "object") {
        buy = upputSO.find((item) => item.strprc === script.strprc);
      } else {
        buy = script;
      }
      eventBus.$emit("menudialog", "order", buy.token, buy.exch, buy.tsym, "b");
    },
    chartOption(script, dwnputSO, upputSO) {
      let chart;
      if (typeof dwnputSO == "object") {
        chart = dwnputSO.find((item) => item.strprc === script.strprc);
      } else if (typeof upputSO == "object") {
        chart = upputSO.find((item) => item.strprc === script.strprc);
      } else {
        chart = script;
      }
      eventBus.$emit("ssd-event", "chart", chart.token, chart.exch, chart.tsym);
    },
    addOption(script, dwnputSO, upputSO) {
      let Add;
      if (typeof dwnputSO == "object") {
        Add = dwnputSO.find((item) => item.strprc === script.strprc);
      } else if (typeof upputSO == "object") {
        Add = upputSO.find((item) => item.strprc === script.strprc);
      } else {
        Add = script;
      }
      eventBus.$emit("addscript-wl", Add);
    },
    sellOption(script, dwnputSO, upputSO) {
      let sell;
      if (typeof dwnputSO == "object") {
        sell = dwnputSO.find((item) => item.strprc === script.strprc);
      } else if (typeof upputSO == "object") {
        sell = upputSO.find((item) => item.strprc === script.strprc);
      } else {
        sell = script;
      }
      eventBus.$emit("menudialog", "order", sell.token, sell.exch, sell.tsym, "s");
    },
    setBaskorder(type, data) {
      eventBus.$emit("bskwatch-event", type, data);
    },
    defltTablestyle() {
      if (this.opvalstrue.true == "7") {
        this.opchtablehead = 15;
        this.simtblwidth = "1580px";
        this.simtblscroll = "scroll";
      } else if (this.opvalstrue.true == "6") {
        this.opchtablehead = 14;
        this.simtblwidth = "1460px";
        this.simtblscroll = "scroll";
      } else if (this.opvalstrue.true == "5") {
        this.opchtablehead = 13;
        this.simtblwidth = "1340px";
        this.simtblscroll = "scroll";
      } else if (this.opvalstrue.true == "4") {
        this.opchtablehead = 12;
        this.simtblwidth = "1220px";
        this.simtblscroll = "scroll";
      } else if (this.opvalstrue.true == "3") {
        this.opchtablehead = 11;
        this.simtblwidth = "1100px";
        this.simtblscroll = "scroll";
      } else if (this.opvalstrue.false == "5") {
        this.opchtablehead = 10;
        this.simtblwidth = "100%";
        this.simtblscroll = "hidden";
      } else if (this.opvalstrue.true == "1" || this.opvalstrue.false == "7") {
        this.simtblwidth = "100%";
        this.simtblscroll = "hidden";
        if (this.opvalstrue.true == "2") {
          this.opchtablehead = 10;
          this.simtblscroll = "scroll";
        } else if (this.opvalstrue.true == "1") {
          this.opchtablehead = 9;
        } else if (this.opvalstrue.false == "7") {
          this.opchtablehead = 8;
        }
      }
    },
    simpleTablestyle() {
      let truecount = [this.ivcheckbox, this.vagacheckbox, this.thetacheckbox, this.gamacheckbox, this.deltacheckbox, this.bitcheckbox, this.askcheckbox];
      let truemap = truecount.reduce((cnt, cur) => ((cnt[cur] = cnt[cur] + 1 || 1), cnt), {});
      if (truemap.true == "7") {
        this.opchtablehead = 15;
        this.simtblwidth = "1580px";
        this.simtblscroll = "scroll";
      } else if (truemap.true == "6") {
        this.opchtablehead = 14;
        this.simtblwidth = "1460px";
        this.simtblscroll = "scroll";
      } else if (truemap.true == "5") {
        this.opchtablehead = 13;
        this.simtblwidth = "1340px";
        this.simtblscroll = "scroll";
      } else if (truemap.true == "4") {
        this.opchtablehead = 12;
        this.simtblwidth = "1220px";
        this.simtblscroll = "scroll";
      } else if (truemap.true == "3") {
        this.opchtablehead = 11;
        this.simtblwidth = "1100px";
        this.simtblscroll = "scroll";
      } else if (truemap.true == "1" || truemap.false == "7" || truemap.true == "2") {
        this.simtblwidth = "100%";
        this.simtblscroll = "hidden";
        if (truemap.true == "2") {
          this.opchtablehead = 10;
          this.simtblscroll = "scroll";
        } else if (truemap.true == "1") {
          this.opchtablehead = 9;
        } else if (truemap.false == "7") {
          this.opchtablehead = 8;
        }
      }
      let opdatstrue = JSON.stringify({ivcheckbox: this.ivcheckbox, vagacheckbox: this.vagacheckbox, thetacheckbox: this.thetacheckbox, gamacheckbox: this.gamacheckbox, deltacheckbox: this.deltacheckbox, askcheckbox: this.askcheckbox, bitcheckbox: this.bitcheckbox});
      let opvalstrue = JSON.stringify({true: truemap.true, false: truemap.false});
      localStorage.setItem(`${this.uid}_opdatstrue`, opdatstrue);
      localStorage.setItem(`${this.uid}_opvalstrue`, opvalstrue);
    },
    async getPositionbook() {
      const data = await getMPosotion();
      this.positiondata = [];
      if (data && data.o && data.o.length > 0) {
        this.positiondata = [...data.o];
        // this.positiondata = [...postD];
        for (let i = 0; i < this.positiondata.length; i++) {
          var a = this.positiondata[i];
          if (a && (["OPTIDX", "OPTFUT"].includes(a.instname) || a.exch == "NFO") && Number(a.netqty) != 0) {
            var uc = this.upcallSO.findIndex((x) => x.tsym == a.tsym);
            var up = this.upputSO.findIndex((x) => x.tsym == a.tsym);
            var dc = this.dwncallSO.findIndex((x) => x.tsym == a.tsym);
            var dp = this.dwnputSO.findIndex((x) => x.tsym == a.tsym);
            if (uc >= 0) {
              this.upcallSO[uc]["p"] = true;
              this.upcallSO[uc]["ppp"] = a;
              this.upcallSO[uc]["qtyp"] = a.netqty;
              this.upcallSO[uc]["qtyclr"] = a.netqty > 0 ? "maingreen--text" : "mainred--text";
              this.$set(this.upcallSO, uc, this.upcallSO[uc]);
            }
            if (up >= 0) {
              this.upputSO[up]["p"] = true;
              this.upputSO[up]["ppp"] = a;
              this.upputSO[up]["qtyp"] = a.netqty;
              this.upputSO[up]["qtyclr"] = a.netqty > 0 ? "maingreen--text" : "mainred--text";
              this.$set(this.upputSO, up, this.upputSO[up]);
            }
            if (dc >= 0) {
              this.dwncallSO[dc]["p"] = true;
              this.dwncallSO[dc]["ppp"] = a;
              this.dwncallSO[dc]["qtyp"] = a.netqty;
              this.dwncallSO[dc]["qtyclr"] = a.netqty > 0 ? "maingreen--text" : "mainred--text";
              this.$set(this.dwncallSO, dc, this.dwncallSO[dc]);
            }
            if (dp >= 0) {
              this.dwnputSO[dp]["p"] = true;
              this.dwnputSO[dp]["ppp"] = a;
              this.dwnputSO[dp]["qtyp"] = a.netqty;
              this.dwnputSO[dp]["qtyclr"] = a.netqty > 0 ? "maingreen--text" : "mainred--text";
              this.$set(this.dwnputSO, dp, this.dwnputSO[dp]);
            }
          }
        }
      }
    },
  },
};
</script>

<style>
.opdatas {
  padding: 0 1px !important;
}

.opstrprcborder {
  border-left: thin solid rgba(0, 0, 0, 0.12);
  border-right: thin solid rgba(0, 0, 0, 0.12);
  padding: 0 1px;
}

.lftbarstatus {
  width: 4px;
  height: 33px;
  position: absolute;
  background-color: var(--my-lftbrdclr-var);
  margin-top: -5px;
  margin-left: -16px;
  z-index: 1;
}

.rgtbarstatus {
  right: 0;
  width: 4px;
  height: 33px;
  position: absolute;
  background-color: var(--my-rgtbrdclr-var);
  margin-top: -27px;
  z-index: 1;
}

.infobarstatus {
  width: 4px !important;
  height: 32px !important;
}

.infoprogress {
  height: 32px !important;
  width: 80px !important;
}

.optionheadsty,
.optionhoversty {
  font-size: 12px !important;
  font-weight: 600 !important;
}

.optiondatasty {
  font-size: 12px !important;
  font-weight: 400 !important;
}

.optionchsty {
  font-size: 9px !important;
}

.opdatacalluphov {
  position: relative !important;
}

tr.opdatacallupbtn {
  position: absolute !important;
  z-index: 3;
}

.lfttrbtn {
  margin-top: -20px;
  display: none !important;
}

.dwnrghtrbtn {
  margin-top: -2px;
  margin-left: 72px;
  display: none !important;
  z-index: 1;
}

.uprghtrbtn {
  margin-top: -20px;
  margin-left: 72px;
  display: none !important;
  z-index: 1;
}

.newhoverbtn {
  margin-right: 4px;
  height: 22px;
  border-radius: 4px;
}

.newhoverbtnsize {
  padding: 2px 12px;
}

.newhovernorsize {
  padding: 2px 6px;
}

.optionbartext {
  position: absolute;
  z-index: 1;
  margin-top: 2px;
  margin-left: -34px;
}

.callprogress,
.putprogress {
  width: 100%;
  height: 24px;
  position: relative;
  bottom: -1px;
}

.callprogress:after,
.putprogress:after {
  content: "\A";
  position: absolute;
  background: #1e53e530;
  top: 0;
  bottom: 0;
  width: var(--my-width-var);
}

.callprogress:after {
  left: 0;
}

.putprogress:after {
  right: 0;
}

.gttbuycrd:hover {
  background-color: #1e53e5 !important;
}

.gttsellcrd:hover {
  background-color: #ff1717 !important;
}

.gttsellcrd:hover .gttselltxt,
.gttbuycrd:hover .gttbuytxt {
  color: #ffffff !important;
}

.totrhover:hover .lfttrbtn,
.totrhover:hover .dwnrghtrbtn,
.totrhover:hover .uprghtrbtn {
  display: flex !important;
}

.tvcharts .v-data-table > .v-data-table__wrapper > table {
  width: var(--my-simtblwidth-var) !important;
  overflow-x: var(--my-simtblscroll-var);
}

.badghlpos {
  top: 8px !important;
  left: 6px !important;
}

.badghrpos {
  top: 8px !important;
  right: 6px !important;
}
</style>

<style lang="scss" scoped>
tbody {
  tr:hover {
    background-color: transparent !important;
  }
}
</style>
