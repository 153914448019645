/* eslint-disable */
import apiurl from '../../apiurl'
import { mynturl } from "../../apiurl";
import postD from '../../posD.json'
import fire from '../../firebase';
let firebase = null

let res = sessionStorage.getItem("c3RhdHVz");
import eventBus from "../../eventBus";

var uid = apiurl.uid
var tok = apiurl.tok
var source = null;

seyCheckwebsocket();

function seyCheckwebsocket() {
    if (res == "dmFsaWR1c2Vy") {
        uid = sessionStorage.getItem('userid');
        tok = sessionStorage.getItem('msession');
    } else {
        res = sessionStorage.getItem("c3RhdHVz");
        setTimeout(function () {
            seyCheckwebsocket();
        }, 100)
    }
}

let myHeaders = new Headers();
let myHeader = new Headers();
myHeaders.append("Content-Type", "application/json");
myHeader.append("Content-Type", "text/plain");

var requestOptions = {
    method: 'POST',
    redirect: 'follow',
    headers: myHeaders
};
var requestMOption = {
    method: 'POST',
    redirect: 'follow',
    headers: myHeader
};

export async function getToken() {
    requestOptions['body'] = "";
    var response = await fetchMyntAPI('https://sess.mynt.in/getoken', requestOptions)
    return response
}
// Auth
export async function getKambalaAuth(item) {
    requestOptions['body'] = `{"LoginId":"${item[0]}", "token":"${item[1]}"}`
    var response = await fetchMyntAPI(apiurl.autho + "kambala_auth", requestOptions)
    return response
}

export async function getDeskLogout(item) {
    requestOptions['body'] = `{"clientid":"${item[0]}", "token":"${item[1]}"}`
    var response = await fetchMyntAPI(apiurl.autho + "desklogout", requestOptions)
    return response
}

export async function getProfiledata(item) {
    requestOptions['body'] = item[0];
    myHeaders.append("clientid", `${item[1]}`);
    myHeaders.append("Authorization", `${item[2]}`);
    var response = await fetchMyntAPI(apiurl.ledger + "profile", requestOptions);
    return response
}

export async function getValidSession(item) {
    requestOptions['body'] = `{"clientid":"${item}", "mobile_unique": "web"}`
    var response = await fetchMyntAPI(apiurl.copy + "validate_session", requestOptions)
    return response
}
export async function getActiveSession(item) {
    if (firebase && firebase.source) {
        source = firebase.source;
    } else {
        await fire.get(fire.child(fire.ref(fire.db), `desk/`)).then((snapshot) => {
            if (snapshot.exists()) {
                firebase = snapshot.val()
                source = firebase.source;
            } else {
                firebase = 'WEB'
                source = "WEB"
            }
        }).catch((error) => {
            source = "WEB"
            console.error(error);
        });
        var soc = new URL(window.location.href).searchParams.get("src");
        var mainsoc = soc ? soc : sessionStorage.getItem(`socsrc`);
        if (mainsoc) {
            source = mainsoc;
            firebase.source = mainsoc;
            sessionStorage.setItem(`socsrc`, source);
        }

        // requestOptions['body'] = "";
        // var res = await fetchMyntAPI(apiurl.sessapi + "strapi/myntdesk", requestOptions)
        // if (res && res.data && res.data.attributes && res.data.attributes.source) {
        //     source = res.data.attributes;
        //     srcis = res.data.attributes.source;
        // } else {
        //     source = "WEB"
        //     srcis = "WEB";
        // }
    }

    var payid = seteEcryption(item)
    requestOptions['body'] = `{"string":"${payid}","source":"${source}"}`
    var response = await fetchMyntAPI(apiurl.copy + "get_sessions", requestOptions)
    var out = JSON.parse(setDecryption(response.str))
    return out
}
export async function getPreDefinedMW() {
    requestOptions['body'] = "";
    var response = await fetchMyntAPI(apiurl.copy + "PreDefinedMW", requestOptions)
    return response
}
export async function getLoggedIn() {
    requestMOption['body'] = `jData={"uid":"${uid}"}&jKey=${tok}`
    var response = await fetchMyntAPI(mynturl.myntapi + "ShowLoggedInSessions", requestMOption)
    return response
}

export async function getHsTokenapi() {
    requestMOption['body'] = `jData={"uid":"${uid}"}&jKey=${tok}`
    var response = await fetchMyntAPI(mynturl.myntapi + "GetHsToken", requestMOption)
    return response
}

export async function getKambalaSearch(data) {
    requestMOption['body'] = data
    var response = await fetchMyntAPI(mynturl.myntapi + 'SearchScrip', requestMOption)
    return response
}

export async function getMwatchlistset(data, url) {
    requestMOption['body'] = data
    var response = await fetchMyntAPI(mynturl.myntapi + url, requestMOption)
    return response
}

export async function getMHoldings() {
    requestMOption['body'] = `jData={"uid":"${uid}","actid":"${uid}","prd":"C"}&jKey=${tok}`
    var response = await fetchMyntAPI(mynturl.myntapi + "Holdings", requestMOption)
    return response
}

export async function getMMHoldings() {
    requestMOption['body'] = `jData={"uid":"${uid}","actid":"${uid}","prd":"C"}&jKey=${tok}`
    var response = await fetchMyntAPI(mynturl.myntapi + "GetMFSSHoldInfo", requestMOption)
    return response
}

export async function getMPosotion() {
    requestMOption['body'] = `jData={"uid":"${uid}","actid":"${uid}"}&jKey=${tok}`
    var data = await fetchMyntAPI(mynturl.myntapi + "PositionBook", requestMOption)
    // data = postD
    var closeposition = [];
    var openposition = [];
    var positiondata = [];
    if (data && data.length > 0) {
        for (let q = 0; q < data.length; q++) {
            data[q]["idx"] = q;
            const bAvg = data[q].exch == "CDS" || data[q].exch == "BCD" ? parseFloat(data[q].totbuyavgprc).toFixed(4) : parseFloat(data[q].totbuyavgprc).toFixed(2);
            const sAvg = data[q].exch == "CDS" || data[q].exch == "BCD" ? parseFloat(data[q].totsellavgprc).toFixed(4) : parseFloat(data[q].totsellavgprc).toFixed(2);
            const bavg = !bAvg ? 0 : bAvg;
            const savg = !sAvg ? 0 : sAvg;

            if (data[q].netqty == 0) {
                data[q]["way"] = "close";
                closeposition.push(data[q]);
            } else {
                data[q]["way"] = "open";
                data[q]["disabled"] = false;
                openposition.push(data[q]);
            }
            data[q]["totbuyavgprc"] = bavg;
            data[q]["totsellavgprc"] = savg;
            data[q]["tokn"] = data[q].token + "_" + q;
            data[q]["avgprc"] = data[q].netupldprc == "0.00" ? data[q].netavgprc : data[q].netupldprc;
            data[q]["crpnl"] = data[q]["rpnl"];
        }
        positiondata = { a: data, o: openposition, c: closeposition };
    } else {
        positiondata = data
    }
    return positiondata
}

export async function getMPosotionCon(item) {
    requestMOption['body'] = `jData=${JSON.stringify(item)}&jKey=${tok}`
    var response = await fetchMyntAPI(mynturl.myntapi + "ProductConversion", requestMOption)
    return response
}

export async function getQuotesdata(item) {
    requestMOption['body'] = `jData={"uid":"${uid}","exch":"${item.split('|')[0]}","token":"${item.split('|')[1]}"}&jKey=${tok}`
    var response = await fetchMyntAPI(mynturl.myntapi + "GetQuotes", requestMOption)
    return response
}

export async function getOptionschain(item) {
    requestMOption['body'] = item
    var response = await fetchMyntAPI(mynturl.myntapi + "GetOptionChain", requestMOption)
    return response
}

export async function getMFQuotesdata(item) {
    requestMOption['body'] = `jData={"uid":"${uid}","exch":"${item.split('|')[0]}","token":"${item.split('|')[1]}"}&jKey=${tok}`
    var response = await fetchMyntAPI(mynturl.myntapi + "GetQuotesMF", requestMOption)
    return response
}

export async function getSecuritydata(item) {
    requestMOption['body'] = `jData={"uid":"${uid}","exch":"${item.split('|')[0]}","token":"${item.split('|')[1]}"}&jKey=${tok}`
    var response = await fetchMyntAPI(mynturl.myntapi + "GetSecurityInfo", requestMOption)
    return response
}

export async function getTechnicals(item) {
    requestMOption['body'] = `jData={"uid":"${uid}","exch":"${item.split('|')[0]}","tsym":"${item.split('|')[2]}"}&jKey=${tok}`
    var response = await fetchMyntAPI(mynturl.myntapi + "GetTechnicals", requestMOption)
    return response
}

export async function getLinkedScrips(item) {
    requestMOption['body'] = `jData={"uid":"${uid}","exch":"${item.split('|')[0]}","token":"${item.split('|')[1]}"}&jKey=${tok}`
    var response = await fetchMyntAPI(mynturl.myntapi + "GetLinkedScrips", requestMOption)
    return response
}

export async function getOrderMargin(item) {
    requestMOption['body'] = `jData={"uid":"${uid}","actid":"${uid}","exch":"${item.exch}","tsym":"${item.tsym}","qty":"${item.qty}","prc":"${item.prc}","prd":"${item.prd}","trantype":"${item.trantype}","prctyp":"${item.prctyp}","trgprc":"${item.trgprc}","rorgqty":"0","rorgprc":"0"}&jKey=${tok}`
    var response = await fetchMyntAPI(mynturl.myntapi + "GetOrderMargin", requestMOption)
    return response
}
export async function getBSKMargin(item) {
    requestMOption['body'] = `jData=${JSON.stringify(item)}&jKey=${tok}`
    var response = await fetchMyntAPI(mynturl.myntapi + "GetBasketMargin", requestMOption)
    return response
}

export async function getBrokerage(item) {
    requestMOption['body'] = `jData={"uid":"${uid}","actid":"${uid}","exch":"${item.exch}","tsym":"${item.tsym}","qty":"${item.qty}","prc":"${item.prc}","prd":"${item.prd}","trantype":"${item.trantype}"}&jKey=${tok}`
    var response = await fetchMyntAPI(mynturl.myntapi + "GetBrokerage", requestMOption)
    return response
}

export async function getPlaceOrder(item, type) {
    item['app_inst_id'] = sessionStorage.getItem("imei");
    item['usr_agent'] = navigator.appVersion;
    item['ipaddr'] = localStorage.getItem("uuidipadd");

    requestMOption['body'] = `jData=${JSON.stringify(item)}&jKey=${tok}`
    var response = await fetchMyntAPI(mynturl.myntapi + (type == 'can' ? "CancelOrder" : type == 'mod' ? "ModifyOrder" : "PlaceOrder"), requestMOption)
    return response
}

export async function getSIPOrderset(item, url) {
    requestMOption['body'] = `jData=${JSON.stringify(item)}&jKey=${tok}`
    var response = await fetchMyntAPI(mynturl.myntapi + url, requestMOption)
    return response
}

export async function getGTTPlaceOrder(item, url) {
    requestMOption['body'] = `jData=${JSON.stringify(item)}&jKey=${tok}`
    var response = await fetchMyntAPI(mynturl.myntapi + url, requestMOption)
    return response
}

export async function setMalert(item, type) {
    requestMOption['body'] = `jData=${JSON.stringify(item)}&jKey=${tok}`
    var response = await fetchMyntAPI(mynturl.myntapi + (type == 'cancel' ? 'CancelAlert' : type ? "ModifyAlert" : "SetAlert"), requestMOption)
    return response
}

export async function getMOrderbook(item) {
    requestMOption['body'] = item
    var response = await fetchMyntAPI(mynturl.myntapi + "OrderBook", requestMOption)
    return response
}

export async function getMTradebook(item) {
    requestMOption['body'] = item
    var response = await fetchMyntAPI(mynturl.myntapi + "TradeBook", requestMOption)
    return response
}

export async function getSiporderbook(item) {
    requestMOption['body'] = item
    var response = await fetchMyntAPI(mynturl.myntapi + "SipOrderBook", requestMOption)
    return response
}

export async function getUserDetails() {
    requestMOption['body'] = `jData={"uid":"${uid}"}&jKey=${tok}`
    var response = await fetchMyntAPI(mynturl.myntapi + "UserDetails", requestMOption)
    return response
}


export async function getSingleorderbook(id) {
    requestMOption['body'] = `jData={"uid":"${uid}","norenordno":"${id}"}&jKey=${tok}`
    var response = await fetchMyntAPI(mynturl.myntapi + "SingleOrdHist", requestMOption)
    return response
}

export async function getGttorderbook() {
    requestMOption['body'] = `jData={"uid":"${uid}"}&jKey=${tok}`
    var response = await fetchMyntAPI(mynturl.myntapi + "GetPendingGTTOrder", requestMOption)
    return response
}

export async function setCancelGTT(item) {
    requestMOption['body'] = item
    var response = await fetchMyntAPI(mynturl.myntapi + "CancelGTTOrder", requestMOption)
    return response
}

export async function getAlertAPi(url) {
    requestMOption['body'] = `jData={"uid":"${uid}"}&jKey=${tok}`
    var response = await fetchMyntAPI(mynturl.myntapi + url, requestMOption)
    return response
}
export async function getMLimits() {
    requestMOption['body'] = `jData={"uid":"${uid}","actid":"${uid}"}&jKey=${tok}`
    var response = await fetchMyntAPI(mynturl.myntapi + 'Limits', requestMOption)
    return response
}

export async function getApikeyreq() {
    requestMOption['body'] = `jData={"uid":"${uid}"}&jKey=${tok}`
    var response = await fetchMyntAPI(mynturl.myntapi + "RequestApiKey", requestMOption)
    return response
}

export async function getClientDetails() {
    requestMOption['body'] = `jData={"uid":"${uid}","actid":"${uid}"}&jKey=${tok}`
    var response = await fetchMyntAPI(mynturl.myntapi + "ClientDetails", requestMOption)
    return response
}

export async function getMyntLogout(inn) {
    requestMOption['body'] = `jData={"uid":"${uid}","source":"${inn}"}&jKey=${tok}`
    var response = await fetchMyntAPI(mynturl.myntapi + "Logout", requestMOption)
    return response
}

export async function getEXPosition() {
    requestOptions['body'] = `{"client_id":"${uid}"}`
    var response = await fetchMyntAPI(apiurl.exmynt + "api/GetPosition", requestOptions)
    return response
}

export async function getOIbars(tsym) {
    requestOptions['body'] = `{"value":"${tsym}","count":"30"}`
    var response = await fetchMyntAPI(apiurl.exmynt + "/get_oi_bar", requestOptions)
    return response
}

// single stocks equity API
export async function getPostPnL(data) {
    requestOptions['body'] = JSON.stringify({ "clientid": uid, "session": tok, "source": mynturl.source, "chartdata": data })
    var response = await fetchMyntAPI(apiurl.eqapi + "positionpnlmargin", requestOptions)
    return response
}

export async function getssNews() {
    requestOptions['body'] = "";
    var response = await fetchMyntAPI(apiurl.sessapi + "newsfeedin?pagesize=5&pagecount=1&filterdate=monthly", { method: 'get' })
    return response
}

export async function getCorporateact() {
    requestOptions['body'] = "";
    var response = await fetchMyntAPI(apiurl.iposapi + "getCorporateAction", requestOptions)
    return response
}

export async function getssDetails(item) {
    requestOptions['body'] = `{"symbol":"${item}"}`
    var response = await fetchMyntAPI(apiurl.eqapi + "stockFundamentalDetails", requestOptions)
    return response
}

export async function getQuotedata(item) {
    requestOptions['body'] = `{"symbol":"${item}"}`
    var response = await fetchMyntAPI(apiurl.eqapi + "getQuotes", requestOptions)
    return response
}
export async function getSectordata() {
    requestOptions['body'] = "";
    var response = await fetchMyntAPI(apiurl.eqapi + "getSector", requestOptions)
    return response
}

export async function getIndexList() {
    requestOptions['body'] = "";
    var response = await fetchMyntAPI(apiurl.eqapi + "GetIndexList", requestOptions)
    return response
}

export async function getTopList(item) {
    requestOptions['body'] = `{"exch":"${item[0]}","bskt":"${item[1]}","crt":"${item[2]}"}`
    var response = await fetchMyntAPI(apiurl.eqapi + "TopList", requestOptions)
    return response
}

export async function getConTentList(item) {
    requestOptions['body'] = `{"exch":"${item[0]}","basket":"${item[1]}","condition":"${item[2]}"}`
    var response = await fetchMyntAPI(apiurl.eqapi + "GetContentList", requestOptions)
    return response
}

export async function getADindices() {
    requestOptions['body'] = "";
    var response = await fetchMyntAPI(apiurl.eqapi + "getadindicesAdvdec", requestOptions)
    return response
}

export async function getADindice(index) {
    requestOptions['body'] = `{"index": "${index}"}`;
    var response = await fetchMyntAPI(apiurl.eqapi + "getadindices", requestOptions)
    return response
}

export async function getHLbreakers() {
    requestOptions['body'] = "";
    var response = await fetchMyntAPI(apiurl.eqapiD + "HLbreakers", requestOptions)
    return response
}

// Mutual Fund API
export async function getMFalldata(item) {
    requestOptions['body'] = `{"filter":"${item[0]}", "Purchase_Allowed":"${item[1]}",  "Redemption_Allowed":"${item[2]}", "Purchase_Transaction_mode":["DP","D"]}`
    var response = await fetchMyntAPI(apiurl.mfapi + "master_file_datas", requestOptions)
    return response
}
export async function getBestMF() {
    requestOptions['body'] = "";
    var response = await fetchMyntAPI(apiurl.mfapi + "z_data", requestOptions)
    return response
}

export async function getMFmandate(item) {
    requestOptions['body'] = `{"client_code":"${item[0]}", "mandate_id":"${item[1]}", "from_date":"01/01/1900", "to_date":"31/12/${new Date().getFullYear()}"}`
    var response = await fetchMyntAPI(apiurl.mfapi + "mandate_details", requestOptions)
    return response
}

export async function getMFAddmandate(item) {
    requestOptions['body'] = `{"client_code":"${item[0]}", "amount":"${item[1]}", "startdate":"${item[2]}", "enddate":"${item[3]}"}`
    var response = await fetchMyntAPI(apiurl.mfapi + "mandate_creation", requestOptions)
    return response
}

export async function getMFsipvalue(item) {
    requestOptions['body'] = `{"isin":"${item[0]}", "scheme_code":"${item[1]}"}`
    var response = await fetchMyntAPI(apiurl.mfapi + "sip_values", requestOptions)
    return response
}

export async function getMFlumsum_order(item) {
    requestOptions['body'] = `{"client_code":"${item[0]}","from_date":"${item[1]}","to_date":"${item[2]}"}`
    var response = await fetchMyntAPI(apiurl.mfapi + "lumsum_orderbook", requestOptions)
    return response
}
export async function getMFholdings() {
    requestOptions['body'] = `{"client_code":"${uid}"}`
    var response = await fetchMyntAPI(apiurl.mfapi + "GetHoldings", requestOptions)
    return response
}

export async function getMFBankdetails() {
    requestOptions['body'] = `{"client_code":"${uid}"}`
    var response = await fetchMyntAPI(apiurl.mfapi + "client_bank_details", requestOptions)
    return response
}

export async function getMFwatchlist(data) {
    requestOptions['body'] = data
    var response = await fetchMyntAPI(apiurl.mfapi + "watchlist", requestOptions)
    return response
}

export async function getMFnofdata() {
    requestOptions['body'] = "";
    var response = await fetchMyntAPI(apiurl.mfapi + "NFO_datas", requestOptions)
    return response
}

export async function getMFsheetdata(key) {
    requestOptions['body'] = "";
    var response = await fetchMyntAPI(apiurl.mfapi + `getFactSheetData?ISIN=${key}`, requestOptions)
    return response
}
export async function getMFNAVchart(key, frmdate, todate) {
    requestOptions['body'] = "";
    var response = await fetchMyntAPI(apiurl.mfapi + `getNavGraph?ISIN=${key}&fromDate=${frmdate}&toDate=${todate}`, requestOptions)
    return response
}

export async function getMFSchemePeers(key, year) {
    requestOptions['body'] = "";
    var response = await fetchMyntAPI(apiurl.mfapi + `getSchemePeers?ISIN=${key}&year=${year}`, requestOptions)
    return response
}

export async function getMFsheetchart(key) {
    requestOptions['body'] = "";
    var response = await fetchMyntAPI(apiurl.mfapi + `getFactSheetGraph?ISIN=${key}`, requestOptions)
    return response
}

export async function getMFrollschart(isin, date, year) {
    requestOptions['body'] = `{"ISIN":${isin},"startdate":"${date}","year":"${year}"}`
    var response = await fetchMyntAPI(apiurl.mfapi + `postRollingReturn`, requestOptions)
    return response
}

export async function getXsiporder(item) {
    requestOptions['body'] = `{"client_code":"${uid}","order_number":"${item[0]}","date":"${item[1]}"}`
    var response = await fetchMyntAPI(apiurl.mfapi + "child_order_details", requestOptions)
    return response
}

export async function getMFcancellum(item, type) {
    requestOptions['body'] = `{"client_code":"${uid}","order_number":"${item}"}`
    var response = await fetchMyntAPI(apiurl.mfapi + (type ? 'lumsum_redemption_cancel' : "lumpsum_cancel"), requestOptions)
    return response
}

export async function getMFcancelxsip(item) {
    requestOptions['body'] = `{"client_code":"${uid}","xsip_reg_no":"${item[0]}","internal_refer_no":"${item[1]}","case_no":"${item[2]}","remarks":"${item[3]}"}`
    var response = await fetchMyntAPI(apiurl.mfapi + "xsip_cancel", requestOptions)
    return response
}

export async function getMFcancelation() {
    requestOptions['body'] = "";
    var response = await fetchMyntAPI(apiurl.mfapi + `reasons`, requestOptions)
    return response
}

// Bolds API
export async function getBondGsec() {
    requestOptions['body'] = "";
    var response = await fetchMyntAPI(apiurl.bondapi + "getcurrentNCB_Gsecdetails", requestOptions)
    return response
}
export async function getBondTbill() {
    requestOptions['body'] = "";
    var response = await fetchMyntAPI(apiurl.bondapi + "getcurrentNCB_TBilldetails", requestOptions)
    return response
}
export async function getBondSdl() {
    requestOptions['body'] = "";
    var response = await fetchMyntAPI(apiurl.bondapi + "getcurrentNCB_SDLdetails", requestOptions)
    return response
}
export async function getBondSgb() {
    requestOptions['body'] = "";
    var response = await fetchMyntAPI(apiurl.bondapi + "getcurrentSGBdetails", requestOptions)
    return response
}


// Ipos API
export async function getIposIpo() {
    // getcurrentIPOdetails
    requestOptions['body'] = "";
    var response = await fetchMyntAPI(apiurl.iposapi + "getcurrentIPOdetails", requestOptions)
    return response
}
export async function getIposPerform(year) {
    requestOptions['body'] = "";
    var response = await fetchMyntAPI(apiurl.iposapi + `ipo_performer?year=${year}`, requestOptions)
    return response
}
export async function getIposSme() {
    // getcurrentSMEIPOdetails
    requestOptions['body'] = "";
    var response = await fetchMyntAPI(apiurl.iposapi + "getcurrentSMEIPOdetails", requestOptions)
    return response
}
export async function getIposbook(item) {
    requestOptions['body'] = `{"client_id": "${item[0]}"}`;
    myHeaders.append("clientid", `${item[0]}`);
    myHeaders.append("Authorization", `${item[1]}`);
    var response = await fetchMyntAPI(apiurl.iposapi + "orderbookIPODetails", requestOptions)
    return response
}
export async function getIposorders(item) {
    requestOptions['body'] = `{"symbol":"${item[2]}","applicationNumber":"${item[3]}","type":"${item[4]}"}`;
    myHeaders.append("clientid", `${item[0]}`);
    myHeaders.append("Authorization", `${item[1]}`);
    var response = await fetchMyntAPI(apiurl.iposapi + "fetchIPODetail", requestOptions)
    return response
}

// collections API
export async function getCollections() {
    requestOptions['body'] = "";
    var response = await fetchMyntAPI(apiurl.collapi + "collectiondetails", requestOptions)
    return response
}

export async function getCollsingledata(item) {
    requestOptions['body'] = JSON.stringify({ basketid: item, client_id: null });
    var response = await fetchMyntAPI(apiurl.collapi + "singlebasketDateils", requestOptions)
    return response
}
export async function getCollchartdata(item) {
    requestOptions['body'] = JSON.stringify({ scripts: item[0], timeFrame: item[1] });
    var response = await fetchMyntAPI(apiurl.eqapi + "basketReturnsChartData", requestOptions)
    return response
}
export async function getCollstockdata(item) {
    requestOptions['body'] = JSON.stringify({ scripts: item[0], timeFrame: item[1] });
    var response = await fetchMyntAPI(apiurl.eqapi + "basketStocksData", requestOptions)
    return response
}

export async function getCollLtpdata(item) {
    requestOptions['body'] = JSON.stringify({ basketid: item, etfs_weights: "" });
    var response = await fetchMyntAPI(apiurl.collapi + "getltp", requestOptions)
    return response
}

export async function getCollplaceorder(url, data, temp) {
    requestOptions['body'] = data;
    myHeaders.append("clientid", `${uid}`);
    myHeaders.append("Authorization", `${temp}`);
    var response = await fetchMyntAPI(apiurl.collapi + url, requestOptions);
    return response
}

export async function getFundsupis(item) {
    requestOptions['body'] = `{"client_id":"${item}"}`;
    var response = await fetchMyntAPI(apiurl.upiurl + "withdraw/view_upi_id", requestOptions);
    return response
}

export async function getUpivpa(item) {
    requestOptions['body'] = `{"VPA":"${item[0]}","clientID":"123456789","bank_acc":"123456789"}`;
    myHeaders.append("clientid", `${item[1]}`);
    myHeaders.append("Authorization", `${item[2]}`);
    var response = await fetchMyntAPI(apiurl.upiurl + "hdfc/upi/checkClientVPA", requestOptions);
    return response
}

export async function getBondLedger(item) {
    requestOptions['body'] = `{"clientid": "${item[0]}"}`;
    var response = await fetchMyntAPI(apiurl.ledger + "all_ledger_balance", requestOptions)
    return response
}

export async function getBondbook(item) {
    requestOptions['body'] = `{"client_id": "${item[0]}"}`;
    myHeaders.append("clientid", `${item[0]}`);
    myHeaders.append("Authorization", `${item[1]}`);
    var response = await fetchMyntAPI(apiurl.bondapi + "orderbookncbDetails", requestOptions)
    return response
}
export async function getBondsgbbook(item) {
    requestOptions['body'] = `{"client_id": "${item[0]}"}`;
    myHeaders.append("clientid", `${item[0]}`);
    myHeaders.append("Authorization", `${item[1]}`);
    var response = await fetchMyntAPI(apiurl.bondapi + "orderbooksgbDetails", requestOptions)
    return response
}

export async function getInvHoldings(item) {
    requestOptions['body'] = `{"cc": "${item[0]}", "to":""}`;
    myHeaders.append("clientid", `${item[0]}`);
    myHeaders.append("Authorization", `${item[1]}`);
    var response = await fetchMyntAPI(apiurl.repapi + "getHoldings", requestOptions)
    return response
}

export async function getLtpdata(item) {
    requestOptions['body'] = `{ "data": ${JSON.stringify(item)} }`
    var response = await fetchMyntAPI(apiurl.asvrapi + "GetLtp", requestOptions)
    return response
}

export async function getGreekoptions(item) {
    requestOptions['body'] = item
    var response = await fetchMyntAPI(apiurl.zebuApiUrl + "getoptiongreeks", requestOptions)
    return response
}


export async function getMasters(tsym) {
    // var response = await fetchMyntjson(apiurl.zebuApiUrl + "Masters", {
    //     method: 'GET',
    //     redirect: 'follow'
    // })
    var response = await fetchMyntjson(apiurl.zebuApiUrl + `findsymbol?tsym=${decodeURIComponent(tsym)}`, {
        method: 'GET',
        redirect: 'follow'
    })
    return response
    // return response != [] ? 'Ok' : {}


}

export async function fetchMyntAPI(path, reqopt, way) {
    try {
        const response = await fetch(path, reqopt);

        let data = await response.json();
        data = JSON.stringify(data);
        data = JSON.parse(data);
        if (way) {
            myHeader = new Headers();
            requestMOption = { method: 'POST', redirect: 'follow' }
            myHeader.append("Content-Type", "text/plain");
            requestMOption['headers'] = myHeader;
        } else {
            myHeaders = new Headers();
            requestOptions = { method: 'POST', redirect: 'follow' }
            myHeaders.append("Content-Type", "application/json");
            requestOptions['headers'] = myHeaders;
        }
        if (data && data.emsg && data.emsg == 'Session Expired :  Invalid Session Key') {
            eventBus.$emit("snack-event", 2, data.emsg);
        } else {
            return data
        }
    } catch (error) {
        if (error.status === 401) {
            eventBus.$emit("snack-event", 2, error);
        }
        if (way) {
            myHeader = new Headers();
            requestMOption = { method: 'POST', redirect: 'follow' }
            myHeader.append("Content-Type", "text/plain");
            requestMOption['headers'] = myHeader;
        } else {
            myHeaders = new Headers();
            requestOptions = { method: 'POST', redirect: 'follow' }
            myHeaders.append("Content-Type", "application/json");
            requestOptions['headers'] = myHeaders;
        }
        let data = 500
        return data
        // throw new Error(error.status);
    }
}

export async function fetchMyntjson(path) {
    try {
        const response = await fetch(path);
        let data = await response.json();
        data = JSON.stringify(data);
        data = JSON.parse(data);
        return data
    } catch (error) {
        let data = []
        return data
    }
}

export function seteEcryption(item) {
    var payld = JSON.stringify({
        clientid: item,
    });
    var CryptoJS = require("crypto-js");

    const payload = payld;
    var derived_key = CryptoJS.enc.Base64.parse(btoa("N#j2L^8pq9Fb$d@1")); //YXNkZmdoamtsenhjdmJubQ==
    var iv = CryptoJS.enc.Utf8.parse("3790514682037125");
    var test = CryptoJS.AES.encrypt(payload, derived_key, { iv: iv, mode: CryptoJS.mode.CBC }).toString();
    return test;
}

export function setDecryption(payld) {
    var CryptoJS = require("crypto-js");
    const payload = payld;
    const derived_key = CryptoJS.enc.Base64.parse(btoa("N#j2L^8pq9Fb$d@1")); //YXNkZmdoamtsenhjdmJubQ==
    const iv = CryptoJS.enc.Utf8.parse("3790514682037125");
    const encryptedData = payload;

    // Decrypt the data using AES
    const decrypted = CryptoJS.AES.decrypt(encryptedData, derived_key, { iv, mode: CryptoJS.mode.CBC });
    const decryptedData = decrypted.toString(CryptoJS.enc.Utf8);
    return decryptedData;
}