<template>
  <div>
    <div style="position: sticky !important; top: 68px; z-index: 1">
      <v-toolbar class="tool-sty elevation-0 pl-4" height="40px" color="cardbg" dense>
        <v-tabs fixed color="maintext" v-model="bodytab" show-arrows dense>
          <v-tab v-for="(t, index) in dashitems" :key="index" class="text-none text-start min-w-fit">
            <span class="text-center font-weight-medium tab-txt">{{ t.txt }}</span></v-tab
          >
          <v-tabs-slider :class="ssdloader ? 'd-none' : 'd-flex'" color="maintext"></v-tabs-slider>
        </v-tabs>
        <v-spacer></v-spacer>
        <v-btn v-if="maindata && maindata.instname != 'UNDIND' && maindata.instname != 'COM'" @click="switchBus.$emit('menudialog', 'order', uniqkey[0], uniqkey[1], uniqkey[2], 's')" :disable="ssdloader" small class="text-none font-weight-bold elevation-0 mr-2 white--text" color="mainred"
          >Sell</v-btn
        >
        <v-btn v-if="maindata && maindata.instname != 'UNDIND' && maindata.instname != 'COM'" @click="switchBus.$emit('menudialog', 'order', uniqkey[0], uniqkey[1], uniqkey[2], 'b')" :disable="ssdloader" small class="text-none font-weight-bold elevation-0 mr-2 white--text" color="maingreen"
          >Buy</v-btn
        >
        <v-tooltip top color="black">
          <template v-slot:activator="{on, attrs}">
            <div v-bind="attrs" v-on="on">
              <v-btn v-if="uniqkey" @click="switchBus.$emit('menudialog', 'alert', uniqkey[0], uniqkey[1], uniqkey[2], 'a')" :disable="ssdloader" icon>
                <img width="18px" src="@/assets/orderbook/5.svg" />
              </v-btn>
            </div>
          </template>
          <span>Create Alert</span>
        </v-tooltip>

        <v-tooltip top color="black">
          <template v-slot:activator="{on, attrs}">
            <div v-bind="attrs" v-on="on">
              <v-btn v-if="maindata && maindata.instname != 'UNDIND' && maindata.instname != 'COM'" @click="switchBus.$emit('menudialog', 'order-GTT', uniqkey[0], uniqkey[1], uniqkey[2], 'b')" :disable="ssdloader" icon>
                <img width="18px" src="@/assets/orderbook/4.svg" />
              </v-btn>
            </div>
          </template>
          <span>Create GTT / GTC</span>
        </v-tooltip>

        <v-tooltip top color="black">
          <template v-slot:activator="{on, attrs}">
            <div v-bind="attrs" v-on="on">
              <v-btn @click="setPopchart()" :disable="ssdloader" small class="mr-2" icon> <img width="20px" :src="require(`@/assets/${!popchart ? 'to' : 'out'}-pip.svg`)" /></v-btn>
            </div>
          </template>
          <span>Pop Chart</span>
        </v-tooltip>
      </v-toolbar>
      <v-progress-linear v-if="ssdloader" color="primary" indeterminate rounded></v-progress-linear>
      <v-divider v-else></v-divider>
    </div>
    <v-tabs-items v-model="bodytab" style="z-index: 0">
      <v-tab-item class="pt-3">
        <StocksOverview />
      </v-tab-item>
      <v-tab-item>
        <div v-if="popdialog">
          <TVChartContainer v-if="!popchart" />
        </div>
        <v-container class="elevation-0" color="transparent" v-else>
          <div class="my-16 py-16 text-center">
            <img width="160px" src="@/assets/topip.svg" />
            <br />
            <span class="text-h6"
              ><b>{{ uniqkey[2] }}</b> on Pop Chart</span
            >
          </div>
        </v-container>
      </v-tab-item>
      <v-tab-item class="pt-3">
        <StockSingle />
      </v-tab-item>
      <v-tab-item>
        <StocksOption />
      </v-tab-item>
      <v-tab-item>
        <v-data-table disable-sort mobile-breakpoint hide-default-footer class="rounded-0 overflow-y-auto futtable" fixed-header :headers="futurechainhead" :items="futuredata">
          <template v-slot:[`item.tsym`]="{item}">
            <td class="pos-rlt">
              <span class="font-weight-medium">{{ item.tsym }}</span>

              <div class="pos-abs futtable-hov" style="bottom: 12px; left: 50%; transform: translate(-50%, 0)">
                <div @click="switchBus.$emit('menudialog', 'order', item.token, item.exch, item.tsym, 'b')" style="min-width: 24px; background-color: var(--maingreen); border-radius: 4px" class="px-2 pt-1 font-weight-bold white--text elevation-0 mr-1 fs-10 text-center cursor-p">B</div>
                <div @click="switchBus.$emit('menudialog', 'order', item.token, item.exch, item.tsym, 's')" style="min-width: 24px; background-color: var(--mainred); border-radius: 4px" class="px-2 pt-1 font-weight-bold white--text elevation-0 mr-1 fs-10 text-center cursor-p">S</div>

                <v-btn @click="switchBus.$emit('addscript-wl', item)" style="border: 1px solid var(--outline)" min-width="20px" color="mainbg" class="px-0 font-weight-bold white--text elevation-0 mr-1" x-small>
                  <v-icon size="18" color="maintext">mdi-bookmark-outline</v-icon>
                </v-btn>

                <v-btn @click="switchBus.$emit('ssd-event', 'chart', item.token, item.exch, item.tsym)" style="border: 1px solid var(--outline)" min-width="20px" color="mainbg" class="px-0 font-weight-bold white--text elevation-0 mr-1" x-small>
                  <v-icon size="18" color="maintext">mdi-chart-line-variant</v-icon>
                </v-btn>
              </div>
            </td>
          </template>
          <template v-slot:[`item.ltp`]="{item}">
            <p class="mb-0 ws-p">
              <span :id="`futt${item.token}ltp`"> {{ item.ltp }} </span>
              <span :id="`futt${item.token}chsclr`" class="fs-12" :class="item.ch > 0 ? 'maingreen--text' : item.ch < 0 ? 'mainred--text' : 'maintext--text'">
                <span :id="`futt${item.token}chs`"> {{ item.ch }} ({{ item.chp }}%)</span></span
              >
            </p>
          </template>
          <template v-slot:[`item.vol`]="{item}">
            <span :id="`futt${item.token}vol`">{{ item.vol }}</span>
          </template>
          <template v-slot:[`item.h_p`]="{item}">
            <span class="text-right" :id="`futt${item.token}h_p`">{{ item.h_p }}</span>
          </template>
          <template v-slot:[`item.l_p`]="{item}">
            <span class="text-right" :id="`futt${item.token}l_p`">{{ item.l_p }}</span>
          </template>

          <template v-slot:[`item.op`]="{item}">
            <span class="text-right" :id="`futt${item.token}op`">{{ item.op }}</span>
          </template>
          <template v-slot:[`item.cp`]="{item}">
            <span class="text-right" :id="`futt${item.token}cp`">{{ item.cp }}</span>
          </template>
          <template v-slot:[`item.ap`]="{item}">
            <span class="text-right" :id="`futt${item.token}ap`">{{ item.ap }}</span>
          </template>
          <template v-slot:no-data>
            <v-col cols="12" class="text-center pa-16">
              <div class="mx-auto">
                <img class="align-self-stretch mx-auto" width="80px" :src="require('@/assets/no data folder.svg')" alt="no data" />
                <h5 class="txt-999 font-weight-regular">There is no Future data here yet!</h5>
              </div>
            </v-col>
          </template>
        </v-data-table>
      </v-tab-item>
      <v-tab-item>
        <v-row no-glutters class="py-4">
          <v-col cols="6" class="pa-0" v-for="(s, ids, d) in securityinfos" :key="d">
            <v-list-item>
              <v-list-item-content class="py-0">
                <v-list-item-subtitle class="subtitle-1 font-weight-medium">{{ ids }}</v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-content class="py-0">
                <v-list-item-title class="subtitle-1 font-weight-bold"
                  ><span>{{ s != undefined ? s : "-" }}</span></v-list-item-title
                >
              </v-list-item-content>
            </v-list-item>
          </v-col>
        </v-row>
      </v-tab-item>
      <v-tab-item>
        <v-row v-if="linkedscrips" class="mt-2 mb-6">
          <v-col cols="12" class="pb-0"><p class="font-weight-bold lh-16 mb-0">Equity</p> </v-col>
          <v-col cols="6" md="4" v-for="(a, s) in linkedscrips.equls" :key="s + a.tsym">
            <v-card class="elevation-0 rounded-lg py-3 px-4" color="secgreen">
              <p class="font-weight-bold fs-14 mb-0">
                {{ a.tsym }} <span class="fs-10 font-weight-medium subtext--text"> {{ a.exch }}</span>
              </p>
              <p class="mb-0 fs-14">
                <span :id="``" class="font-weight-medium"> {{ a.ltp ? a.ltp : "0.00" }} </span>
                <span :id="``" class="fs-12" :class="a.ch > 0 ? 'maingreen--text' : a.ch < 0 ? 'mainred--text' : 'maintext--text'">
                  <span :id="``"> {{ a.ch ? a.ch : "0.00" }} ({{ a.chp ? a.chp : "0.00" }}%)</span></span
                >
              </p>
            </v-card>
          </v-col>
          <v-col cols="12" class="pb-0">
            <v-divider class="mb-4"></v-divider>
            <p class="font-weight-bold lh-16 mb-0">Futures</p>
          </v-col>
          <v-col cols="6" md="4" v-for="(a, s) in futuredata" :key="s + a.tsym">
            <v-card class="elevation-0 rounded-lg py-3 px-4" color="secred">
              <p class="font-weight-bold fs-14 mb-0">
                {{ a.tsym }} <span class="fs-10 font-weight-medium subtext--text"> {{ a.exch }}</span>
              </p>
              <p class="mb-0 fs-14">
                <span :id="``" class="font-weight-medium"> {{ a.ltp ? a.ltp : "0.00" }} </span>
                <span :id="``" class="fs-12" :class="a.ch > 0 ? 'maingreen--text' : a.ch < 0 ? 'mainred--text' : 'maintext--text'">
                  <span :id="``"> {{ a.ch ? a.ch : "0.00" }} ({{ a.chp ? a.chp : "0.00" }}%)</span></span
                >
              </p>
            </v-card>
          </v-col>
          <v-col cols="12">
            <v-divider class="mb-4"></v-divider>
            <p class="font-weight-bold lh-16 mb-0">Options</p>
          </v-col>
          <v-col cols="6" md="4" v-for="(a, s) in linkedscrips.opt_exp" :key="s + a.tsym">
            <v-card class="elevation-0 rounded-lg py-3 px-4" color="secbg">
              <p class="font-weight-bold fs-14 mb-0">
                {{ a.tsym }} <span class="fs-10 font-weight-medium subtext--text"> {{ a.exch }}</span>
              </p>
              <p class="mb-0 fs-14">
                <span :id="``" class="font-weight-medium"> {{ a.ltp ? a.ltp : "0.00" }} </span>
                <span :id="``" class="fs-12" :class="a.ch > 0 ? 'maingreen--text' : a.ch < 0 ? 'mainred--text' : 'maintext--text'">
                  <span :id="``"> {{ a.ch ? a.ch : "0.00" }} ({{ a.chp ? a.chp : "0.00" }}%)</span></span
                >
              </p>
            </v-card>
          </v-col>
        </v-row>
      </v-tab-item>
    </v-tabs-items>
    <div :class="popdialog ? 'd-none' : 'd-flex'" class="rounded-lg">
      <v-card id="popdiv" width="320px" height="180px" class="rounded-lg overflow-hidden pos-rlt">
        <v-card class="pos-abs rounded-0 elevation-0" color="transparent" height="40px" width="100%" id="popdivheader">
          <v-btn @click="setPopchart()" :disable="ssdloader" color="maintext" x-small class="mr-2 mt-2 float-right" icon> <v-icon>mdi-close</v-icon></v-btn>
        </v-card>
        <TVChartContainer v-if="popchart" />
      </v-card>
    </div>
  </div>
</template>

<script>
import StockSingle from "./StockSingle.vue";
import StocksOverview from "./StocksOverview.vue";
import StocksOption from "./StocksOption.vue";

import eventBus from "../../../eventBus.js";
import TVChartContainer from "@/components/TVChartContainer.vue";
import {getssDetails, getQuotesdata, getLinkedScrips, getSecuritydata, getTechnicals} from "@/components/mixins/getAPIdata";

export default {
  ssddetail: {},
  ssdreqdata: {},

  data: () => ({
    ssdloader: true,
    bodytab: 0,
    dashitems: [
      {tab: true, txt: "Overview", class: "ws-p"},
      {tab: true, txt: "Chart", class: "ws-p"},
      {tab: true, txt: "Fundamental", class: "ws-p"},
      {tab: true, txt: "Option", class: "ws-p"},
      {tab: true, txt: "Future", class: "ws-p"},
      {tab: true, txt: "Info", class: "ws-p"},
      // {tab: true, txt: "Linked Scrips", class: "ws-p"},
    ],

    uid: null,
    stoken: null,
    mtoken: null,

    equlsdata: [],
    optiondata: [],
    futuredata: [],

    securityinfos: [],

    uniqkey: [],

    switchBus: null,

    statknow: 0,
    maindata: null,

    linkedscrips: [],

    popchart: false,
    popdialog: true,
  }),

  created() {
    let params = this.$route.params.val;
    if (params) {
      this.setLoadingdata(params[0], params[1], params[2], params[3]);
    } else {
      this.$router.push("/stocks");
    }
    localStorage.removeItem("adv_chart");

    this.switchBus = eventBus;
  },
  computed: {
    futurechainhead() {
      return [
        {text: "SYMBOL", value: "tsym", class: "ws-p"},
        {text: "LTP CH(%)", value: "ltp", class: "ws-p"},
        // { text: 'CH', value: 'ch', class: 'ws-p' },
        // { text: 'BID(Qty)', value: 'bid_qty', class: 'ws-p' },
        {text: "BID", value: "bid", class: "ws-p", align: "right"},
        {text: "ASK", value: "ask", class: "ws-p", align: "right"},
        // { text: 'ASK(Qty)', value: 'ask_qty', class: 'ws-p' },
        {text: "AVG PRICE", value: "ap", class: "ws-p", align: "right"},
        {text: "HIGN", value: "h_p", class: "ws-p", align: "right"},
        {text: "LOW", value: "l_p", class: "ws-p", align: "right"},
        {text: "OPEN", value: "op", class: "ws-p", align: "right"},
        {text: "CLOSE", value: "cp", class: "ws-p", align: "right"},
        {text: "VOL", value: "vol", class: "ws-p"},
      ];
    },
  },
  mounted() {
    eventBus.$emit("tabBar-load");
    this.$emit("receive", "stk");
    eventBus.$emit("login-event");

    eventBus.$on("user-event", () => {
      let res = sessionStorage.getItem("c3RhdHVz");
      if (res == "dmFsaWR1c2Vy") {
        this.mtoken = sessionStorage.getItem("msession");
        this.stoken = sessionStorage.getItem("usession");
        this.uid = sessionStorage.getItem("userid");
      } else {
        this.$router.push("/stocks");
      }
    });

    eventBus.$on("ssd-event", (a, token, exch, tsym) => {
      let path = window.location;
      if (path.pathname == "/stocks/details" && token != this.uniqkey[0]) {
        this.ssdloader = true;
        this.setWebsocket("unsub-D", this.futuredata, "ssd");
        window.ssddetail = [];
        this.setLoadingdata(a, token, exch, tsym);
      }
    });

    eventBus.$on("web-scoketConn", (data, page) => {
      if (page == "stockSSD") {
        this.optionChainDataParse(data);
      }
    });

    window.ssdreqdata = {data: []};

    dragElement(document.getElementById("popdiv"));

    function dragElement(elmnt) {
      var pos1 = 0,
        pos2 = 0,
        pos3 = 0,
        pos4 = 0;
      if (document.getElementById(elmnt.id + "header")) {
        // if present, the header is where you move the DIV from:
        document.getElementById(elmnt.id + "header").onmousedown = dragMouseDown;
      } else {
        // otherwise, move the DIV from anywhere inside the DIV:
        elmnt.onmousedown = dragMouseDown;
      }

      function dragMouseDown(e) {
        e = e || window.event;
        e.preventDefault();
        // get the mouse cursor position at startup:
        pos3 = e.clientX;
        pos4 = e.clientY;
        document.onmouseup = closeDragElement;
        // call a function whenever the cursor moves:
        document.onmousemove = elementDrag;
      }

      function elementDrag(e) {
        e = e || window.event;
        e.preventDefault();
        // calculate the new cursor position:
        pos1 = pos3 - e.clientX;
        pos2 = pos4 - e.clientY;
        pos3 = e.clientX;
        pos4 = e.clientY;
        // set the element's new position:
        elmnt.style.top = elmnt.offsetTop - pos2 + "px";
        elmnt.style.left = elmnt.offsetLeft - pos1 + "px";
      }

      function closeDragElement() {
        // stop moving when mouse button is released:
        document.onmouseup = null;
        document.onmousemove = null;
      }
    }
  },
  beforeDestroy() {
    eventBus.$off("user-event");
    eventBus.$off("ssd-event");
    // eventBus.$off('web-scoketConn');
  },

  methods: {
    setPopchart() {
      localStorage.setItem("adv_chart", this.popdialog ? 0 : 1);
      this.popdialog = !this.popdialog;
      setTimeout(() => {
        this.popchart = !this.popchart;
      }, 10);
    },
    async setLoadingdata(type, token, exch, tsym) {
      this.setTabstat(type, token, exch, tsym);
      if (this.uniqkey[0] != type && this.uniqkey[1] != token) {
        localStorage.setItem("ssdtsym", `${exch}:${tsym}`);
        localStorage.setItem("ssdtoken", token);

        var l;
        var si;
        this.uniqkey = Array.from([token, exch, tsym]);
        if (window.ssdreqdata && window.ssdreqdata.data && window.ssdreqdata.data[token]) {
          l = window.ssdreqdata.data[token].l;
          si = window.ssdreqdata.data[token].i;
          if (window.ssdreqdata.data[token].s && window.ssdreqdata.data[token].s.fundamental) {
            this.dashitems[2].tab = true;
            window.ssddetail = [window.ssdreqdata.data[token].q, window.ssdreqdata.data[token].s, l, si];
          } else {
            this.dashitems[2].tab = false;
            window.ssddetail = [window.ssdreqdata.data[token].q, "no data", l, si];
          }
          this.maindata = window.ssdreqdata.data[token].q;
        } else {
          var q = await getQuotesdata(`${exch}|${token}|${tsym}`);
          var s = exch == "BSE" || exch == "NSE" ? await getssDetails(`${exch}:${tsym}`) : null;
          l = await getLinkedScrips(`${exch}|${token}|${tsym}`);
          si = await getSecuritydata(`${exch}|${token}`);
          if (s && s.fundamental) {
            this.dashitems[2].tab = true;
            window.ssddetail = [q, s, l, si];
          } else {
            this.dashitems[2].tab = false;
            window.ssddetail = [q, "no data", l, si];
          }
          window.ssdreqdata.data[`${token}`] = {q: q, s: s, l: l, t: null, i: si};
          this.linkedscrips = l;
          this.maindata = q;
        }
        this.upScriptdata(token, exch, tsym, l);
        if (si) {
          this.setScriptinfo(si);
        }
      }
    },

    setTabstat(a, token, exch, tsym) {
      if (a >= 0) {
        if (this.bodytab == 1) {
          window.tvWidget.activeChart().setSymbol(`${exch}:${tsym}`);
        }
      } else if (a == "chart" && this.bodytab == 1) {
        this.bodytab = 1;
        window.tvWidget.activeChart().setSymbol(`${exch}:${tsym}`);
      } else if (a == "detail" || a == "depth") {
        this.bodytab = 0;
      } else if (a == "chart") {
        this.bodytab = 1;
      } else if (a == "Funda") {
        this.bodytab = 2;
      }
    },
    async upScriptdata(token, exch, tsym, l) {
      var windata = window.ssdreqdata.data;
      let data = l;
      let tech;
      if (exch == "NSE" || exch == "BSE") {
        if (windata && windata[token] && windata[token].t) {
          tech = windata[token].t;
        } else {
          tech = await getTechnicals(`${exch}|${token}|${tsym}`);
          windata[token].t = tech;
        }
      }
      if (data && data.stat == "Ok") {
        this.equlsdata = [];
        if (this.maindata && this.maindata.instname != "UNDIND" && this.maindata.instname != "COM" && data.equls && data.equls.length > 0) {
          this.dashitems[2].tab = true;
          this.equlsdata = data.equls;
        } else {
          this.dashitems[2].tab = false;
        }

        this.optiondata = [];
        if (this.maindata && data.opt_exp && data.opt_exp.length > 0) {
          this.dashitems[3].tab = true;
          this.optiondata = data.opt_exp;
        } else {
          this.dashitems[3].tab = false;
        }

        this.futuredata = [];
        if (this.maindata && this.maindata.instname != "COM" && data.fut && data.fut.length > 0) {
          this.dashitems[4].tab = true;
          for (let l = 0; l < data.fut.length; l++) {
            if (data.fut[l].exch == "NFO" || data.fut[l].exch == "BFO") {
              // data.fut[l]['ser'] = data.fut[l].tsym.split(/(\d+)/);
              // data.fut[l].tsym = data.fut[l].ser[0];
              // data.fut[l]['exp'] = 'FUT';
              // data.fut[l].ser = data.fut[l].instname.includes('OPT') ? `${data.fut[l].ser[1]} ${data.fut[l].ser[2]} ${data.fut[l].ser[5] ? data.fut[l].ser[3] : ''}` : `${data.fut[l].ser[1]} ${data.fut[l].ser[2].slice(0, 3)} ${data.fut[l].ser[3] && data.fut[l].ser[3] != 'FUT' ? data.fut[l].ser[3] : ''}`
            }
            this.futuredata.push(data.fut[l]);
            this.futuredata.sort((a, b) => new Date(a.exd) - new Date(b.exd));
          }
          this.setWebsocket("sub", this.futuredata, "ssd");
        } else {
          this.dashitems[4].tab = false;
        }
      }
      this.ssdloader = false;
    },
    setScriptinfo(si) {
      this.securityinfos = {
        "Company Name": si.cname,
        "Trading Symbol": si.tsym,
        "Symbol Name": si.symnam,
        Exchange: si.exch,
        Segment: si.seg,
        "Expiry Date": si.exd,
        "Intrument Name": si.instname,
        "Strike Price": si.strprc,
        "Option Type": si.optt,
        ISIN: si.isin,
        "Tick Size": si.ti,
        "Lot Size": si.ls,
        "Price precision": si.pp,
        Multiplier: si.mult,
        "gn/gd * pn/pd": si.gp_nd,
        "Price Units": si.prcunt,
        "Price Quote Qty": si.prcqqty,
        "Trade Units": si.trdunt,
        "Delivery Units": si.delunt,
        "Freeze Qty": si.frzqty,
        "scripupdate Gsm Ind": si.gsmind,
        "Elm Buy Margin": si.elmbmrg,
        "Elm Sell Margin": si.elmsmrg,
        "Additional Long Margin": si.addbmrg,
        "Additional Short Margin": si.addsmrg,
        "Special Long Margin": si.splbmrg,
        "Special Short Margin": si.splsmrg,
        "Delivery Margin": si.delmrg,
        "Tender Margin": si.tenmrg,
        "Tender Start Date": si.tenstrd,
        "Tender End Eate": si.tenendd,
        "Exercise Start Date": si.exestrd,
        "Exercise End Date": si.exeendd,
        "Market type": si.mkt_t,
        "Issue date": si.issue_d,
        "Listing date": si.listing_d,
        "last trading date": si.last_trd_d,
        "Elm Margin": si.elmmrg,
        "Var Margin": si.varmrg,
        "Exposure Margin": si.expmrg,
        "((GN / GD) * (PN/PD))": si.prcftr_d,
        "Non tradable instruments": si.nontrd,
        "Weekly Option, W1, W2, W3, W4 th week": si.weekly,
      };
    },
    setWebsocket(flow, data, is) {
      eventBus.$emit("web-scoketOn", flow, data, is, "stockSSD");
    },

    optionChainDataParse(data) {
      let w = this.futuredata.findIndex((o) => o.token == data.token);
      if (w >= 0 && this.futuredata[w].token == data.token) {
        this.futuredata[w].ltp = Number(data.lp).toFixed(2);
        this.futuredata[w]["ch"] = Number(data.ch) > 0 || Number(data.ch) < 0 ? Number(data.ch).toFixed(2) : 0;
        this.futuredata[w]["chp"] = Number(data.chp).toFixed(2);
        this.futuredata[w]["vol"] = Number(data.volume);
        this.futuredata[w]["h_p"] = Number(data.high_price).toFixed(2);
        this.futuredata[w]["l_p"] = Number(data.low_price).toFixed(2);
        this.futuredata[w]["op"] = Number(data.open_price).toFixed(2);
        this.futuredata[w]["cp"] = Number(data.prev_close_price).toFixed(2);
        this.futuredata[w]["ap"] = Number(data.ap).toFixed(2);

        this.futuredata[w]["bid_qty"] = data.bid_qty;
        this.futuredata[w]["bid"] = Number(data.bid).toFixed(2);
        this.futuredata[w]["ask_qty"] = data.ask_qty;
        this.futuredata[w]["ask"] = Number(data.ask).toFixed(2);

        let tag = document.getElementById(`futt${data.token}ltp`);
        if (tag) {
          document.getElementById(`futt${data.token}ltp`).innerHTML = this.futuredata[w].ltp;
          document.getElementById(`futt${data.token}chs`).innerHTML = ` ${this.futuredata[w].ch} (${this.futuredata[w].chp}%)`;
          eventBus.$emit("color-event", `futt${data.token}chsclr`, this.futuredata[w].ch > 0 ? "maingreen--text" : this.futuredata[w].ch < 0 ? "mainred--text" : "none");
          document.getElementById(`futt${data.token}vol`).innerHTML = this.futuredata[w].vol;
          document.getElementById(`futt${data.token}h_p`).innerHTML = this.futuredata[w].h_p;
          document.getElementById(`futt${data.token}l_p`).innerHTML = this.futuredata[w].l_p;
          document.getElementById(`futt${data.token}op`).innerHTML = this.futuredata[w].op;
          document.getElementById(`futt${data.token}cp`).innerHTML = this.futuredata[w].cp;
          document.getElementById(`futt${data.token}ap`).innerHTML = this.futuredata[w].ap;
        }

        // this.$set(this.futuredata, w, this.futuredata[w]);
      }
    },
  },
  components: {StocksOption, StockSingle, StocksOverview, TVChartContainer},
};
</script>
