<template>
  <div>
    <v-app-bar color="cardbg" width="100%" permanent class="cust-appbar">
      <img @click="toHome()" :src="require(`@/assets/${$vuetify.theme.dark ? 'logod' : 'logo'}.svg`)" width="80px" alt="zebulogo" class="mr-4" />

      <v-btn :to="t.path" @click="appMainPage(t)" v-for="(t, index) in dashitems" :key="index" text plain class="menu-btn text-capitalize d-none d-sm-flex">
        <v-badge color="primary" :content="t.type" :value="t.type ? true : false">
          <span active-class="font-weight-bold" class="menu-text-sty">{{ t.txt }}</span>
        </v-badge>
      </v-btn>
      <v-spacer></v-spacer>

      <v-menu v-if="useris" close-on-click offset-y max-width="240px" class="table-menu">
        <template v-slot:activator="{on, attrs}">
          <v-btn v-bind="attrs" v-on="on" text small class="mx-1 elevation-0 text-none font-weight-medium subtitle-1 d-none d-md-none d-sm-flex px-2">
            More <v-icon class="ml-1">mdi-chevron-down</v-icon>
          </v-btn>
        </template>
        <v-card class="table-menu-list">
          <v-list>
            <v-list-item to="/positions">
              <v-list-item-content>
                <v-list-item-title class="font-weight-medium fs-14 mb-0">Positions</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
             <v-list-item to="/holdings">
              <v-list-item-content>
                <v-list-item-title class="font-weight-medium fs-14 mb-0">Holdings</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-divider class="mx-3"></v-divider>
             <v-list-item to="/orders">
              <v-list-item-content>
                <v-list-item-title class="font-weight-medium fs-14 mb-0">Orders</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
             <v-list-item to="/notification">
              <v-list-item-content>
                <v-list-item-title class="font-weight-medium fs-14 mb-0">Notification</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
             <v-list-item to="/funds">
              <v-list-item-content>
                <v-list-item-title class="font-weight-medium fs-14 mb-0">Funds</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card>
      </v-menu>

      <!-- <div v-if="!useris"></div> -->
      <v-btn v-if="useris" to="/positions" text plain class="menu-btn text-capitalize d-none d-md-flex"><span class="menu-text-sty">Positions</span></v-btn>
      <v-btn v-if="useris" to="/holdings" text plain class="menu-btn text-capitalize d-none d-md-flex mr-2"><span class="menu-text-sty">Holdings</span></v-btn>
      <!-- <v-btn v-if="useris" to="/orders" text plain class="menu-btn text-capitalize d-none d-md-flex mr-2"><span class="menu-text-sty">Orders</span></v-btn> -->

      <v-tooltip bottom color="black">
        <template v-slot:activator="{on, attrs}">
          <div v-bind="attrs" v-on="on">
            <v-btn v-if="useris" to="/orders" icon small class="mr-4 d-none d-md-flex">
              <img :src="require(`@/assets/${$vuetify.theme.dark ? 'ordersd' : 'orders'}.svg`)" />
            </v-btn>
          </div>
        </template>
        <span>Orders</span>
      </v-tooltip>

      <v-tooltip bottom color="black">
        <template v-slot:activator="{on, attrs}">
          <div v-bind="attrs" v-on="on">
            <v-btn v-if="useris" to="/notification" icon small class="mr-4 d-none d-md-flex">
              <img :src="require(`@/assets/${$vuetify.theme.dark ? 'notificationd' : 'notification'}.svg`)" />
            </v-btn>
          </div>
        </template>
        <span>Notification</span>
      </v-tooltip>
      <!-- :href="`https://fund.mynt.in/?uid=${uid}&token=${token}`" -->
      <v-tooltip bottom color="black">
        <template v-slot:activator="{on, attrs}">
          <div v-bind="attrs" v-on="on">
            <v-btn v-if="useris" to="/funds" icon small class="mr-4 d-none d-md-flex">
              <img :src="require(`@/assets/${$vuetify.theme.dark ? 'fundd' : 'fund'}.svg`)" />
            </v-btn>
          </div>
        </template>
        <span>Funds</span>
      </v-tooltip>

      <v-tooltip color="black" bottom v-if="showalert">
        <template v-slot:activator="{on, attrs}">
          <v-btn @click="(loading = true), setConnection()" v-bind="attrs" v-on="on" fab color="warning" small class="mx-1 elevation-0">
            <v-icon>mdi-spin mdi-sync-alert</v-icon>
          </v-btn>
        </template>
        <span class="warning--text font-weight-bold">Session is inactive, Click to refresh</span>
      </v-tooltip>

      <v-menu v-else-if="clientdata && clientdata.CLIENT_NAME" close-on-click offset-y max-width="240px" class="table-menu">
        <template v-slot:activator="{on, attrs}">
          <v-btn v-bind="attrs" v-on="on" fab color="secbg" small class="mx-1 elevation-0">
            <span class="title font-weight-bold">
              {{ clientdata.CLIENT_NAME ? clientdata.CLIENT_NAME.slice(0, 1) : "-" }}
            </span>
          </v-btn>
        </template>
        <v-card class="table-menu-list">
          <v-list>
            <v-list-item class="mb-2">
              <v-list-item-avatar color="secbg">
                <span class="title font-weight-bold">
                  {{ clientdata.CLIENT_NAME ? clientdata.CLIENT_NAME.slice(0, 1) : "-" }}
                </span>
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title class="fs-14 font-weight-bold mb-1">{{ clientdata.CLIENT_NAME ? clientdata.CLIENT_NAME : "-" }}</v-list-item-title>
                <v-list-item-subtitle class="fs-12 subtext--text">User ID {{ clientdata.CLIENT_ID ? clientdata.CLIENT_ID : "" }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-divider></v-divider>

            <v-list-item :href="`https://profile.mynt.in/profile?uid=${uid}&token=${token}`" target="_blank">
              <v-list-item-icon class="mr-3 text-center">
                <img width="24px" class="pl-1" :src="require(`@/assets/usermenu/${$vuetify.theme.dark ? '1d' : '1'}.svg`)" />
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title class="font-weight-medium fs-14 mb-0">My Account</v-list-item-title>
                <v-list-item-subtitle class="fs-10 subtext--text">Profile, Bank, Segment, MTF, Closure, Downloads</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-divider class="mx-3"></v-divider>
            <v-list-item :href="`https://profile.mynt.in/ledger?uid=${uid}&token=${token}`" target="_blank">
              <v-list-item-icon class="mr-3 text-center">
                <img width="22px" class="pl-1" :src="require(`@/assets/usermenu/${$vuetify.theme.dark ? 'reportsd' : 'reports'}.svg`)" />
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title class="font-weight-medium fs-14 mb-0">Reports</v-list-item-title>
                <v-list-item-subtitle class="fs-10 subtext--text">Ledger, Holdings, PnL, Tax, Downloads</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-divider class="mx-3"></v-divider>
            <v-list-item :href="`https://profile.mynt.in/corporateaction?uid=${uid}&token=${token}`" target="_blank">
              <v-list-item-icon class="mr-3 text-center">
                <img width="22px" class="pl-1" :src="require(`@/assets/usermenu/${$vuetify.theme.dark ? 'coad' : 'coa'}.svg`)" />
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title class="font-weight-medium fs-14 mb-0">Corporation Action</v-list-item-title>
                <v-list-item-subtitle class="fs-10 subtext--text">Buyback, Delisting, Takeover, OFS</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item :href="`https://profile.mynt.in/pledge?uid=${uid}&token=${token}`" target="_blank">
              <v-list-item-icon class="mr-3 text-center">
                <img width="24px" class="pl-1" :src="require(`@/assets/usermenu/${$vuetify.theme.dark ? 'pledged' : 'pledge'}.svg`)" />
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title class="font-weight-medium fs-14 mb-0">Pledge & Unpledge</v-list-item-title>
                <v-list-item-subtitle class="fs-10 subtext--text">Stocks held by various accounts</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-divider class="mx-3"></v-divider>
            <v-list-item href="https://zebuetrade.com/referral" target="_blank">
              <v-list-item-icon class="mr-3 text-center">
                <img width="22px" class="pl-1" :src="require(`@/assets/usermenu/${$vuetify.theme.dark ? 'referald' : 'referal'}.svg`)" />
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title class="font-weight-medium fs-14 mb-0">Refer</v-list-item-title>
                <v-list-item-subtitle class="fs-10 subtext--text">Refer your family & friends</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item href="https://zebuetrade.com/contactus" target="_blank">
              <v-list-item-icon class="mr-3 text-center">
                <img width="22px" class="pl-1" :src="require(`@/assets/usermenu/${$vuetify.theme.dark ? '6d' : '6'}.svg`)" />
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title class="font-weight-medium fs-14 mb-0">Help & Support</v-list-item-title>
                <v-list-item-subtitle class="fs-10 subtext--text">Sales, Support & Desk</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item to="/settings">
              <v-list-item-icon class="mr-3 text-center">
                <img width="22px" class="pl-1" :src="require(`@/assets/usermenu/${$vuetify.theme.dark ? '7d' : '7'}.svg`)" />
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title class="font-weight-medium fs-14 mb-0">Setting</v-list-item-title>
                <v-list-item-subtitle class="fs-10 subtext--text">API key, Change password, Themes, Log</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-divider class="mx-3"></v-divider>
            <v-list-item @click="logOut()">
              <v-list-item-icon class="mr-3 text-center">
                <img width="22px" class="pl-1" :src="require(`@/assets/usermenu/${$vuetify.theme.dark ? '8d' : '8'}.svg`)" />
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title class="font-weight-medium fs-14">Logout</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card>
      </v-menu>
      <v-tooltip bottom color="black" v-else>
        <template v-slot:activator="{on, attrs}">
          <v-btn @click="goLogin()" v-bind="attrs" v-on="on" fab color="secbg" small class="mx-1 elevation-0">
            <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M19 7.5C19 9.70914 17.2091 11.5 15 11.5V13.5C18.3137 13.5 21 10.8137 21 7.5H19ZM15 11.5C12.7909 11.5 11 9.70914 11 7.5H9C9 10.8137 11.6863 13.5 15 13.5V11.5ZM11 7.5C11 5.29086 12.7909 3.5 15 3.5V1.5C11.6863 1.5 9 4.18629 9 7.5H11ZM15 3.5C17.2091 3.5 19 5.29086 19 7.5H21C21 4.18629 18.3137 1.5 15 1.5V3.5ZM11.25 17.25H18.75V15.25H11.25V17.25ZM18.75 25.25H11.25V27.25H18.75V25.25ZM11.25 25.25C9.04086 25.25 7.25 23.4591 7.25 21.25H5.25C5.25 24.5637 7.93629 27.25 11.25 27.25V25.25ZM22.75 21.25C22.75 23.4591 20.9591 25.25 18.75 25.25V27.25C22.0637 27.25 24.75 24.5637 24.75 21.25H22.75ZM18.75 17.25C20.9591 17.25 22.75 19.0409 22.75 21.25H24.75C24.75 17.9363 22.0637 15.25 18.75 15.25V17.25ZM11.25 15.25C7.93629 15.25 5.25 17.9363 5.25 21.25H7.25C7.25 19.0409 9.04086 17.25 11.25 17.25V15.25Z"
                :fill="$vuetify.theme.dark ? '#2E65F6' : '#0037B7'"
              />
            </svg>
          </v-btn>
        </template>
        <span>Login with ZEBU</span>
      </v-tooltip>
    </v-app-bar>
  </div>
</template>

<script>
import eventBus from "../eventBus.js";
import {getDeskLogout, getMyntLogout, getProfiledata} from "./mixins/getAPIdata";
import {mynturl} from "../apiurl.js";

export default {
  data: () => ({
    loading: true,
    switchBus: null,
    uid: "",
    token: "",
    mtoken: "",
    dashitems: [
      {txt: "Stocks", path: "/stocks", idx: 0},
      // {txt: "F&O", path: "/fno", idx: 1},
      {txt: "Mutual Fund", path: "/mutualfund", idx: 1},
      {txt: "IPOs", path: "/ipo", idx: 2},
      {txt: "Bonds", path: "/bonds", idx: 3},
      {txt: "Collection", path: "/collection", idx: 4, type: "Beta"},
      // { txt: 'details', path: '/stocks/details', idx: 4 },
    ],
    userdata: null,
    clientdata: [],
    appbaris: null,
    useris: false,
    showalert: false,
  }),
  created() {
    //
  },
  async mounted() {
    // eventBus.$emit('login-event');
    this.switchBus = eventBus;
    eventBus.$on("profile-event", (type) => {
      if (type == "set" && this.userdata) {
        setTimeout(() => {
          eventBus.$emit("profile-event", "get", this.userdata);
        }, 10);
      }
    });

    eventBus.$on("app-user-event", () => {
      this.loading = true;
      let res = sessionStorage.getItem("c3RhdHVz");
      if (res == "dmFsaWR1c2Vy") {
        this.token = sessionStorage.getItem("usession");
        this.mtoken = sessionStorage.getItem("msession");
        this.uid = sessionStorage.getItem("userid");
        this.useris = true;

        var dall = JSON.stringify({
          client_id: this.uid,
        });
        let date1 = this.encryptionFunction(dall);

        let data = JSON.stringify({
          string: date1,
        });

        this.getUserdata(data);
      } else {
        this.useris = false;
        this.loading = false;
      }
    });
    this.token = "";
    this.uid = "";
    eventBus.$on("show-alert", (params) => {
      if (params.callback) {
        this.showalert = true;
      }
    });
    eventBus.$on("storage-reset", (type) => {
      this.setLocalstoredata(type);
    });
  },
  beforeDestroy() {
    eventBus.$off("app-user-event");
    eventBus.$off("profile-event");
    eventBus.$off("storage-reset");
  },
  methods: {
    setConnection() {
      window.location.reload();
    },
    async getUserdata(data) {
      let config = await getProfiledata([data, this.uid, this.token]);

      if (config.str && config.emsg != "invalid token") {
        let res = JSON.parse(this.decryptionFunction(config.str));
        if (res && res.client_data) {
          this.clientdata = res.client_data;
          this.userdata = res;
          eventBus.$emit("profile-event", "get", this.userdata);
        } else {
          this.userdata = null;
          this.clientdata = [];
        }
      }
      this.loading = false;
    },
    appMainPage(t) {
      eventBus.$emit("setappbar-event", t.txt);
      this.appbaris = t.path;
    },
    setLocalstoredata(type) {
      this.useris = false;
      sessionStorage.clear();
      if (type) {
        this.setConnection();
      }
      this.token = "";
      this.uid = "";
    },
    async logOut() {
      await getDeskLogout([this.uid, this.token]);
      await getMyntLogout(mynturl.source);
      this.setLocalstoredata(true);
    },
    goLogin() {
      eventBus.$emit("login");
    },
    encryptionFunction(payld) {
      var CryptoJS = require("crypto-js");
      const payload = payld;
      var derived_key = CryptoJS.enc.Base64.parse(btoa("N#j2L^8pq9Fb$d@1")); //YXNkZmdoamtsenhjdmJubQ==
      var iv = CryptoJS.enc.Utf8.parse("3790514682037125");
      var test = CryptoJS.AES.encrypt(payload, derived_key, {
        iv: iv,
        mode: CryptoJS.mode.CBC,
      }).toString();
      return test;
    },
    decryptionFunction(payld) {
      var CryptoJS = require("crypto-js");

      const payload = payld;
      const derived_key = CryptoJS.enc.Base64.parse(btoa("N#j2L^8pq9Fb$d@1")); //YXNkZmdoamtsenhjdmJubQ==
      const iv = CryptoJS.enc.Utf8.parse("3790514682037125");
      const encryptedData = payload;

      // Decrypt the data using AES
      const decrypted = CryptoJS.AES.decrypt(encryptedData, derived_key, {
        iv,
        mode: CryptoJS.mode.CBC,
      });
      const decryptedData = decrypted.toString(CryptoJS.enc.Utf8);

      return decryptedData;
    },
    toHome() {
      this.$router.push("/");
    },
  },
};
</script>
